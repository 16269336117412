<template>
    <td class="actions">
        <button class="edit" @click="edit" v-if="settings.hasEdit">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>
        </button>
        <button class="delete" @click="remove" v-if="settings.hasRemove">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
        </button>
    </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "RowActions",
    props: {
      settings: {
        type: Object,
        default: {
          hasRemove: true,
          hasEdit: true
        }
      }
    },
    methods: {
        edit: function() {
            this.$emit("edit");
        },
        remove: function() {
            this.$emit("remove");
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
}

.actions button {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 2px 0px 2px;
}

.actions button.edit {
  background-color: $orange;
}

.actions button.edit:hover {
  background-color: $light-orange;
}

.actions button.edit svg {
  display: block;
  width: 16px;
  height: 16px;
  fill: $white;
}

.actions button.delete {
  background-color: $red;
}

.actions button.delete:hover {
  background-color: $light-red;
}

.actions button.delete svg {
  display: block;
  width: 24px;
  height: 24px;
  fill: $white;
}
</style>
