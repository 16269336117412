<template>
    <div class="role-input">
    <div class="modifiable-tag" v-for="role in activeRoles" :key="role.id">
        <div class="tag">
            <p>{{ role.label }}</p>
        </div>
        <button class="remove" v-on:click="removeRole(role.id)" v-show="role.name != 'user'">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
            </svg>
        </button>
    </div>

    <div class="dropdown">
        <button v-on:click="toggleDropdown">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
        </button>
        <ul v-bind:class="isOpen ? 'open' : 'closed'">
            <li v-for="role in inactiveRoles" :key="role.id" v-on:click="addRole(role.id)">
                {{ role.label }}
            </li>
        </ul>
    </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IRole } from '../../models/Role'

export default defineComponent({
  name: 'RoleInput',
  props: {
    currentActiveRoles: Array as () => IRole[],
    allRoles: Array as () => IRole[]
  },
  methods: {
    toggleDropdown: function () {
      this.initRoleList();
      this.isOpen = !this.isOpen
    },
    addRole: function (roleId: number) {
      this.inactiveRoles.forEach((element, index) => {
        if (roleId === element.id) {
          this.inactiveRoles.splice(index, 1)
          this.activeRoles.push(element)
          this.isOpen = false
          this.$emit('roles-changed', this.activeRoles.slice())
        }
      })
    },
    removeRole: function (roleId: number) {
      this.activeRoles.forEach((element, index) => {
        if (roleId === element.id) {
          this.activeRoles.splice(index, 1)
          this.inactiveRoles.push(element)
          this.isOpen = false
          this.$emit('roles-changed', this.activeRoles.slice())
        }
      })
    },
    initRoleList: function () {
      this.inactiveRoles.splice(0)
      this.allRoles?.forEach((role) => {
        let contains = false
        this.activeRoles = this.currentActiveRoles!.slice(0)
        this.activeRoles?.forEach(element => {
          if (element.id === role.id) {
            contains = true
          }
        })

        if (!contains) {
          this.inactiveRoles.push(role)
        }
      })
    }
  },
  data: function () {
    return {
      isOpen: false,
      activeRoles: [] as IRole[],
      inactiveRoles: [] as IRole[]
    }
  },
  watch: {
    currentActiveRoles: function() {
      this.initRoleList();
    }
  }
})
</script>

<style scoped lang="scss">

@import '../../scss/global.scss';

.role-input {
    display: flex;
    margin-bottom: 15px;
}

.role-input .modifiable-tag {
    display: flex;
    background-color: $blue;
    height: 24px;
}

.role-input .modifiable-tag .tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 5px 0px 5px;
}

.role-input .modifiable-tag .tag p {
    margin: 0;
    font-size: 0.8em;
}

.role-input .modifiable-tag .remove {
    background-color: $red;
    color: $white;
    transition: background-color 0.3s, color 0.3s;
}

.role-input .modifiable-tag .remove:hover {
    background-color: $light-red;
}

.role-input .modifiable-tag .remove svg {
    display: block;
    width: 10px;
    height: 10px;
}

.role-input button.add {
    background-color: $green;
    color: $white;
    margin-left: 10px;
}

.role-input button.add svg {
    display: block;
    width: 24px;
    height: 24px;
}

.modifiable-tag {
  margin-right: 5px;
}

.dropdown {
    position: relative;
    background-color: $dark-gray;
}

.dropdown button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $green;
    padding: 0px;
    transition: background-color 0.3s;
    width: 24px;
    height: 24px;
}

.dropdown button svg {
    display: flex;
    width: 24px;
    height: 24px;
}

.dropdown button:hover {
    background-color: $light-green;
}

.dropdown ul {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $dark-green;
    z-index: 1;
}

.dropdown ul li {
    font-size: 0.8em;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.dropdown ul li:hover {
    background-color: $green;
}

.dropdown ul.open {
    display: block;
}

.dropdown ul.closed {
    display: none;
}

</style>
