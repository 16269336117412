<template>
    <div class="overlay" v-bind:class="isOpen ? 'open' : 'closed'">

        <div class="dialog" :class="properties.type">
            <div class="top-bar">
                <button class="close" v-on:click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                    </svg>
                </button>
            </div>

            <div class="content">

                <div class="container">
                    <div class="input-fields">
                        <div class="row-container">
                            <number-input class="id-input" :label="'Id'" :step="1" :min="1" :max="9999" :decimals="0" v-model="internalProduct.id" v-model:errorMsg="inputErrors.id" :readonly="properties.type == 'edit'"></number-input>
                            <slider :label="'Beschikbaar'" v-model="internalProduct.isAvailable" class="available"></slider>
                        </div>

                        <text-input class="label-input" :label="'Label'" v-model="internalProduct.label" v-model:errorMsg="inputErrors.label"></text-input>

                        <div class="row-container">
                            <number-input class="price-input" :label="'Prijs'" :step="1" :min="1" :max="9999" :useComma="true" :decimals="2" v-model="internalProduct.price"></number-input>
                            <dropdown class="unit-input" :label="'Eenheid'" :placeholder="'Selecteer...'" :initialSelection="0" :items="[{'id': 'kg', 'label': 'kg'}, {'id': 'pc', 'label': 'st'}]" v-model="internalProduct.priceUnit"></dropdown>
                        </div>

                        <dropdown :label="'Categorie'" :items="categorySelectionList" v-model="internalProduct.categoryId"></dropdown>
                    </div>

                    <image-input :placeholder="imgInput.placeholder" v-model="imgInput.data" :settings="imgInput.settings"></image-input>
                </div>

                <dialog-actions :isLoading="isLoading" :errorMessage="errorMessage" :submitText="properties.submitText" :cancelText="properties.cancelText" @submit="submit" @cancel="close" class="actions"></dialog-actions>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextInput from "../input/TextInput.vue";
import NumberInput from "../input/NumberInput.vue";
import Dropdown from "../input/Dropdown.vue";
import Slider from "../input/Slider.vue";
import ImageInput from "../input/ImageInput.vue";
import { IProduct } from "../../models/Product";
import DialogActions from "../dialog/DialogActions.vue";
import { IDialogProperties } from "@/models/DialogProperties";
import { ICategory } from "../../models/Category";
import axios from "axios";
import { ICustomAxiosRequestConfig } from "../../models/ICustomAxiosRequestConfig";

import {
  required,
  email,
  between,
  or,
  sameAs,
  url,
  minValue,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { IDropdownItem } from "../../models/DropdownItem";
import IImageInputData from "@/models/input/IImageInputData";
import IImageInputSettings from "@/models/input/IImageInputSettings";

export default defineComponent({
  name: "ProductDialog",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    TextInput,
    NumberInput,
    Dropdown,
    ImageInput,
    DialogActions,
    Slider
  },
  props: {
    properties: Object as () => IDialogProperties,
    modelValue: {
      type: Object as () => IProduct,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    categories: {
      type: Array as () => ICategory[],
      default: []
    },
    settings: Object,
    inputErrors: Object,
    isLoading: Boolean,
  },
  data: function () {
    return {
      imgInput: {
        placeholder: axios.defaults.baseURL + "/img/product/placeholder.jpg",
        data: {
          img: "",
          isChanged: false,
        } as IImageInputData,
        settings: {
          width: 320,
          height: 320,
          stencilWidth: 300,
          stencilHeight: 300,
          aspectRatio: 1 / 1,
          resultWidth: 280,
          resultHeight: 280,
        } as IImageInputSettings,
      },
      errorMessage: "",
      internalProduct: {
        id: 0,
        label: "",
        price: 0,
        priceUnit: "kg",
        image: "",
        isAvailable: false,
        categoryId: "0",
      },
    };
  },
  validations() {
    return {
      internalProduct: {
        id: { required, minValue: minValue(1) },
        label: { required },
        price: { required, between: between(0, 999999) },
        priceUnit: { required },
        categoryId: { required },
      },
    };
  },
  methods: {
    open: function () {
      this.$emit("update:isOpen", true);
    },

    close: function () {
      this.$emit("update:isOpen", false);
    },

    submit: function () {
      this.v$.$touch();

      if (!this.v$.$error) {
        this.$emit("update:isLoading", true);
        let imgData = this.imgInput.data.isChanged
          ? this.imgInput.data.img
          : null;

        this.$emit("submit", this.internalProduct, imgData);
      } else {
        if ((this.v$.internalProduct as any).label.required.$invalid)
          this.$emit("update:inputErrors", {
            ...this.inputErrors,
            label: "Label leeg",
          });
      }
    },

    loadImage: function (imageBlob: string) {
      this.internalProduct.image = imageBlob;
    },
  },
  computed: {
    categorySelectionList: function (): IDropdownItem[] {
      let arr = new Array<IDropdownItem>();
      this.categories!.forEach((category: ICategory) => {
        category.subCategories.forEach((sub) => {
          arr.push({ id: sub.id.toString(), label: sub.label });
        });
      });

      return arr;
    },
  },
  watch: {
    modelValue: function () {
      this.internalProduct = JSON.parse(JSON.stringify(this.modelValue!));
      let url = this.settings!.imgPath + this.internalProduct.image;
      axios
        .get(url, {
          responseType: "arraybuffer",
          customSettings: { useAuth: false },
        } as ICustomAxiosRequestConfig)
        .then((response) => {
          var arrayBufferView = new Uint8Array(response.data);
          var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(blob);
          this.imgInput.data.img = imageUrl;
        });
    },
    isOpen: function () {
      if (!this.isOpen) this.$emit("update:isLoading", false);
    },
    inputErrors: function () {
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.input-fields {
  margin-right: 15px;
}

.id-input {
  width: 100px;
}

.label-input {
  margin-bottom: 15px;
}

.price-input {
  width: 150px;
  margin-right: 10px;
}

.unit-input {
  width: 75px;
}

.dialog.add {
  border-color: $green;
}

.dialog.edit {
  border-color: $orange;
}

.row-container {
  display: flex;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
}

.available {
  margin-left: 15px;
}

.actions {
  margin-top: 15px;
}

.dialog .actions button {
  height: 40px;
  padding: 0px 15px 0px 15px;
  border-style: solid;
  border-width: 2px;
  background-color: rgba(0, 0, 0, 0);
  font-weight: bold;

  transition: background-color 0.3s, color 0.3s;
}

:deep(.dialog.add .submit) {
  border-color: $green;
  color: $green;
  margin-right: 5px;
}

:deep(.dialog.add .submit:hover) {
  background-color: $green;
  color: $white;
}

:deep(.dialog .cancel) {
  border-color: $red;
  color: $red;
}

:deep(.dialog .cancel:hover) {
  background-color: $red;
  color: $white;
}

:deep(.dialog.edit .submit) {
  border-color: $orange;
  color: $orange;
  margin-right: 5px;
}

:deep(.dialog.edit .submit:hover) {
  background-color: $orange;
  color: $white;
}

.dialog .content .input-fields {
  display: flex;
  flex-direction: column;
}

.dialog .content .price-input {
  display: flex;
}

.dialog .content .container {
  display: flex;
}

:deep(.dialog.add .loading) {
  background-color: $green;
}

:deep(.dialog.edit .loading) {
  background-color: $orange;
}

:deep(.image-size) {
  width: 280px;
  height: 280px;
}
</style>
