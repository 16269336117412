export var QueryFactory = {

    methods: {

        createQuery: function(queryLabel: string, query: string, filters: Array<any>): string {

            let q = "&query." + queryLabel + "=" + query + "&" + queryLabel + ".targets=";

            filters.forEach((element: any, index: number) => {
                let id = Object.keys(element)[0];
                if (element.active)
                    q += id + ",";
            });

            if (q.slice(-1) == ",")
                q = q.slice(0, -1);

            return q;
        }

    }

}