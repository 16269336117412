<template>
    <div class="parent" @click="toggle" @blur="setState(false)" tabindex="0">
        <div class="input-container dropdown interactable"  :class="{ focus: hasFocus }">
            <div class="frame">
                <label :class="{ floating: shouldLabelFloat }" v-if="label !== undefined && label !== ''">{{ label }}</label>
                <input type="text" :value="selectedText" disabled>
            </div>
        </div>
        <ul class="items" v-if="hasFocus">
            <li v-for="item in items" :key="item.id" @click="select(item.id); toggle();">{{ item.label }}</li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IDropdownItem } from "../../models/DropdownItem";

export default defineComponent({
  name: "Dropdown",
  props: {
    placeholder: {
      type: String,
      default: "Select..."
    },
    items: Array as () => IDropdownItem[],
    label: String,
    classes: Array,
    initialSelection: Number,
    modelValue: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      isExtended: false,
      hasFocus: false,
    };
  },
  methods: {
    setState: function (value: boolean) {
        this.hasFocus = value;
    },
    toggle: function() {
      this.hasFocus = !this.hasFocus;
    },
    select: function (id: string) {
      this.hasFocus = false;
      this.$emit("update:modelValue", id);
    },
  },
  computed: {
    selectedText: function (): string {
      let item = this.items!.find((o) => o.id == this.modelValue);

      if (item !== undefined) return item!.label;
      else return "";
    },
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.modelValue != "";
    },
  },
  mounted: function () {
    if (this.initialSelection !== undefined)
      this.select(this.items![this.initialSelection!].id);
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

input {
  pointer-events: none;
}

button {
  padding: 0;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  font-family: inherit;
}

.parent {
    position: relative;
}

.dropdown {
    cursor: pointer;
}

.dropdown p {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding-top: 1em;
}

.items {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $blue;
  z-index: 999;
  max-height: 125px;
  overflow-y: scroll;
}

.items li {
  color: $white;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
}

.items li:hover {
  background-color: $light-blue;
}

</style>