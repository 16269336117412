
import IImageInputSettings from "@/models/input/IImageInputSettings";
import { defineComponent } from "vue";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import IImageInputData from "../../models/input/IImageInputData";

export default defineComponent({
  name: "ImageInput",
  components: {
    Cropper,
  },
  props: {
    placeholder: String,
    modelValue: {
      type: Object as () => IImageInputData,
      required: true
    },
    settings: {
      type: Object as () => IImageInputSettings
    }
  },
  data: function () {
    return {
      internalSource: "",
      isEditing: false,
      loadingNewImage: false
    };
  },
  methods: {
    chooseFile: function () {
      (this.$refs.input as any).click();
    },
    startEditing: function () {
      this.isEditing = true;
    },
    stopEditing: function () {
      this.isEditing = false;
      (this.$refs.cropper as any).reset();
    },
    applyModification: function () {
      let { canvas } = (this.$refs.cropper as any).getResult();
      if (canvas) {
        canvas.toBlob((o: Blob) => {
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(o);
          this.$emit("update:modelValue", { img: imageUrl, isChanged: true });

          this.isEditing = false;
        });
      }
    },
    changeWhileUpload: function(obj: any) {
      if (this.loadingNewImage) {
        this.applyModification();
        this.loadingNewImage = false;
      }
    },
    loadImage: function (event: Event) {
      let files = (event.target as HTMLInputElement).files!;

      if (files.length > 0) {
        this.loadingNewImage = true;
        this.internalSource = URL.createObjectURL(files[0]);
      }
    },
    removeImage: function () {
        this.$emit("update:modelValue", { img: "", isChanged: true });
    },
  },
  watch: {
      "modelValue.img": function(newValue: any) {
          this.internalSource = newValue;
          if ((this.modelValue as any).img == "")
            this.internalSource = this.placeholder!;
      }
  }
});
