
import { defineComponent } from "vue";
import UserDialog from "../components/users/UserDialog.vue";
import Row from "../components/table/Row.vue";
import { IUser } from "../models/User";
import { IUserDTO } from "../models/UserDTO";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { IUserPrototype } from "../models/UserPrototype";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import RowActions from "../components/table/RowActions.vue";
import { IRole } from "../models/Role";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import { QueryFactory } from "../mixins/QueryFactory";

interface IUserRow {
  visible: boolean;
  isDeleting: boolean;
  user: IUser;
}

export default defineComponent({
  name: "Users",
  mixins: [QueryFactory],
  components: {
    Row,
    UserDialog,
    Pagination,
    SearchField,
    Column,
    WarningDialog,
    RowActions,
    TableView,
    SkeletonRow,
  },
  data: function () {
    return {
      notification: "",
      isLoading: false,
      editWindow: {
        isOpen: false,
        user: {} as IUser,
      },
      addWindow: {
        isOpen: false,
      },
      removeDialog: {
        isOpen: false,
      },
      filters: [
        {
          id: "Id",
          active: true,
        },
        {
          name: "Naam",
          active: true,
        },
        {
          email: "E-mail",
          active: true
        }
      ],
      allRoles: [] as IRole[],
      userRows: [] as IUserRow[],
      pageIndex: 0,
      pageSize: 25,
      totalSize: 0,
      autoCompleteList: [],
      addErrorMessage: "",
      editErrorMessage: "",
      promptForRemoval: false,
      queuedForRemoval: {} as IUser,
      query: "",
      autoCompleteLoading: false,
    };
  },
  methods: {
    addUser: function (user: IUserPrototype) {
      axios.post("/users", { user: user }).then((response) => {
        if (response.status === 201) {
          this.addWindow.isOpen = false;
          this.loadPage(this.pageIndex, this.pageSize);
        } else {
          this.addErrorMessage = "Er ging iets fout";
        }
      });
    },
    editUser: function (user: IUser) {
      axios
        .put("/users", { user: user })
        .then((response) => {
          if (response.status === 200) {
            this.loadPage(this.pageIndex, this.pageSize);
            this.editWindow.isOpen = false;
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.editErrorMessage = "Er ging iets fout";
        });
    },
    openAddDialog: function () {
      this.addWindow.isOpen = true;
    },
    openEditDialog: function (user: IUser) {
      this.editWindow.user = user;
      this.editWindow.isOpen = true;
    },
    loadRoles: function () {
      return axios
        .get("/roles")
        .then((response) => {
          this.allRoles = response.data.roles;
        })
        .catch((reason) => {
          this.$emit("notification", "Er ging iets mis bij het laden", "error");
        });
    },
    loadPage: function (pageIndex: number, pageSize: number) {
      this.isLoading = true;
      this.userRows.splice(0);

      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
      if (this.query != "") {
        params += this.createQuery("user", this.query, this.filters);
      }

      return axios
        .get("/users" + params, {})
        .then((response) => {
          if (response.status === 200) {
            this.pageIndex = pageIndex;
            this.totalSize = parseInt(response.data.totalSize);

            this.setTable(response.data.users);

            this.isLoading = false;
          }
        })
        .catch((response) => {});
    },
    requestRemove: function (user: IUser) {
      this.removeDialog.isOpen = true;
      this.queuedForRemoval = user;
    },
    removeUser: function () {
      let row = {} as IUserRow;
      this.userRows.forEach((element, index) => {
        if (element.user.id === this.queuedForRemoval.id) {
          row = element;
          return false;
        }
      });

      axios
        .delete("/users", { params: { id: this.queuedForRemoval.id } })
        .then((response) => {
          row.visible = false;
          row.isDeleting = false;

          if (response.status === 204)
            this.loadPage(this.pageIndex, this.pageSize);

          if (response.status === 500)
            this.$emit("notification", "Verwijderen mislukt", "error");

          this.removeDialog.isOpen = false;
        });
    },
    setTable: function (users: IUserDTO[]) {
      this.userRows = [];
      users.forEach((element) => {
        let roles = [] as IRole[];

        element.roles.forEach((element) => {
          let roleObj = this.allRoles.find((o) => {
            return o.id == element;
          });

          roles.push(roleObj!);
        });

        let user = {
          id: element.id,
          name: element.name,
          email: element.email,
          isEmailVerified: element.isEmailVerified,
          roles: roles,
        };

        this.userRows.push({
          visible: false,
          isDeleting: false,
          user: user,
        });
      });

      let index = 0;
      let setUserCallback = () => {
        if (users.length != 0 && index < users.length) {
          this.userRows[index++].visible = true;
          setTimeout(() => {
            setUserCallback();
          }, 30);
        }
      };

      setUserCallback();
    },
    search: function () {
      console.log("search " + this.query);
      this.autoCompleteList = [];
      this.loadPage(0, this.pageSize);
    },
    autoComplete: function (query: string) {
      this.autoCompleteList = [];

      if (query !== "") {
        this.autoCompleteLoading = true;
        axios
          .get("/users/suggestions", { params: { query: query } })
          .then((response) => {
            this.autoCompleteList = response.data.suggestions;
            this.autoCompleteLoading = false;
          })
          .catch(() => {
            this.autoCompleteLoading = false;
          });
      }
    },
    closeWarning: function () {
      this.promptForRemoval = false;
    },
  },
  mounted() {
    this.isLoading = true;

    this.loadRoles().then(() => {
      this.loadPage(0, this.pageSize).finally(() => {
        this.isLoading = false;
      });
    });
  },
});
