import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import App from '../App.vue';
import Users from '../views/Users.vue'
import Products from "../views/Products.vue"
import Account from "../views/Account.vue"
import Login from "../views/Login.vue"
import RequestPasswordReset from "../views/RequestPasswordReset.vue";
import SubmitPasswordReset from "../views/SubmitPasswordReset.vue";
import Website from "../views/Website.vue";
import Orders from "../views/Orders.vue";
import Developer from "../views/Developer.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/users',
    name: 'Users',
    meta: { layout: "sidebar-layout" },
    component: Users
  },
  {
    path: '/account',
    name: 'Account',
    meta: { layout: "sidebar-layout" },
    component: Account
  },
  {
    path: "/products",
    name: "Products",
    meta: { layout: "sidebar-layout" },
    component: Products
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "default-layout" },
    component: Login
  },
  {
    path: "/password-reset/request",
    name: "RequestPasswordReset",
    meta: { layout: "default-layout" },
    component: RequestPasswordReset
  },
  {
    path: "/password-reset/submit",
    name: "SubmitPasswordReset",
    meta: { layout: "default-layout" },
    component: SubmitPasswordReset
  },
  {
    path: "/website",
    name: "Website",
    meta: { layout: "sidebar-layout" },
    component: Website
  },
  {
    path: "/orders",
    name: "Orders",
    meta: { layout: "sidebar-layout" },
    component: Orders
  },
  {
    path: "/developer",
    name: "Developer",
    meta: { layout: "sidebar-layout" },
    component: Developer
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;