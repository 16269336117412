<template>
    <div>
        <label>{{ label }}</label>
        <div class="checkbox-container" @click="toggle">
            <input type="checkbox" v-model="state">
            <div class="thumb"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Slider",
  props: {
    label: {
      type: String
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      state: false
    };
  },
  methods: {
    toggle: function () {
      this.state = !this.state;
      this.$emit("update:modelValue", this.state);
    }
  },
  watch: {
      modelValue: function() 
      {
          this.state = this.modelValue;
      }
  }
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.checkbox-container {
  position: relative;
  background-color: $blue;
  width: 48px;
  height: 24px;

  cursor: pointer;
}

.checkbox-container:hover {
  background-color: $light-blue;
}

.thumb {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;

  transition: left 0.3s;
}

input[type="checkbox"] {
  display: none;
}

input:checked ~ .thumb {
  left: 50%;
  background-color: $green;
}

input ~ .thumb {
  left: 0;
  background-color: $red;
}

</style>
