<template>
    <div class="search-container">
        <button class="filter-button" @click="toggleFilter" v-if="filters.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
          </svg>
        </button>
        <ul class="filter-list" v-show="isFilterVisible">
          <li v-for="filter in filters" :key="Object.keys(filter)[0]">
            <checkbox v-model="filter['active']"></checkbox>
            <span>{{ filter[Object.keys(filter)[0]] }}</span>
          </li>
        </ul>
        <text-input type="text" :label="'Zoeken'" :modelValue="modelValue" @update:modelValue="change" @focus="focusChange(true)" @blur="focusChange(false)" :enableError="false"></text-input>
        <button class="submit" @click="submitQuery">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
        </button>
        <ul class="autocomplete">
            <li v-show="autoCompleteLoading" class="loading">
                <div class="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <span>Aan het laden</span>
            </li>
            <li v-for="autoComplete in autoCompleteList" :key="autoComplete" @click="selectAutoComplete(autoComplete)">{{ autoComplete }}</li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextInput from "./input/TextInput.vue";
import Checkbox from "./input/Checkbox.vue";

export default defineComponent({
  name: "SearchField",
  components: {
    TextInput,
    Checkbox
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    autoCompleteList: {
      type: Array,
      default: []
    },
    autoCompleteLoading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: []
    }
  },
  data: function () {
    return {
      hasFocus: false,
      isFilterVisible: false
    };
  },
  methods: {
    submitQuery: function () {
      this.$emit("submit");
    },
    change: function (value: string) {
      this.$emit("update:modelValue", value);
    },
    focusChange: function(focus: boolean) {
      this.hasFocus = focus;
    },
    selectAutoComplete: function(value: string) {
      this.$emit("submit", value);
    },
    toggleFilter: function() {
      this.isFilterVisible = !this.isFilterVisible;
    }
  },
  watch: {
      autoCompleteLoading: function() {
      },
      autoCompleteList: function(list) {
      }
  }
});
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

.search-container {
  position: relative;
  display: flex;
}

.filter-button {
  width: 32px;
  background-color: $blue;
  margin-right: 5px;

  transition: background-color 0.2s;
}

.filter-button:hover {
  background-color: $light-blue;
}

.filter-button svg {
  fill: white;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.filter-list {
  display: flex;
  top: 100%;
  left: 0;
  width: 250px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0px 10px;

  z-index: 999;

  margin-right: 5px;
}

.filter-list li {
  display: flex;
  margin-right: 10px;
}

.filter-list li span {
  margin-left: 10px;
  line-height: 1;
}

.submit {
  background-color: $blue;
  color: $white;
  width: 32px;
  height: 100%;
  pointer-events: auto;

  transition: background-color 0.2s;
}

.submit:hover {
  background-color: $light-blue;
}

.autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 160px;
  width: 100%;

  background-color: $light-gray;

  z-index: 10;
}

.autocomplete li {
  padding: 5px;
  cursor: pointer;
}

.autocomplete li:hover {
  background-color: $lighter-gray;
}

.autocomplete .loading {
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.autocomplete .loading span {
  margin-left: 10px;
}

.autocomplete .loader {
  height: 16px;
}

</style>