<template>
    <component :is="layout" :account="account" v-slot="scope">
        <router-view @setAccount="setAccount" @notification="scope.showMessage"></router-view>
    </component>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import SidebarLayout from "./layouts/SidebarLayout.vue";
import { IUser } from "./models/User";

export default defineComponent({
  name: "App",
  components: {
    DefaultLayout,
    SidebarLayout
  },
  data: function() {
      return {
        account: {} as IUser
      }
  },
  methods: {
    setAccount: function(account: IUser) {
        this.account = account;
    }
  },
  computed: {
    layout: function () {
      return this.$route.meta.layout;
    },
  },
});
</script>

<style lang="scss">
@import "./scss/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0px;
  color: $white;
  background-color: $green;
}

.add-button:hover {
  background-color: $light-green;
}

.add-button svg {
  display: block;
  width: 24px;
  height: 24px;
}

html,
body {
 background: #2B2B33;
  height: 100%;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Ubuntu", sans-serif;
  color: $white;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  height: 100%;
  max-height: 100vh;
}

button {
  border: none;
  cursor: pointer;
}

h1,
h2 {
  display: inline-block;
  color: $white;
  font-weight: 300;
  width: auto;
  margin: 0px 0px 35px 0px;
}

h1 {
  padding: 0px 0px 0px 25px;
  border-left: 2px solid $white;
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.main {
  padding: 30px;
  position: relative;
  box-sizing: border-box;
  height: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  font-family: inherit;
  margin-bottom: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  filter: blur(5);
}

.overlay.open {
  display: block;
}

.overlay.closed {
  display: none;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2B2B33;
  padding: 40px;
  border-left: 2px solid $orange;
  min-width: 600px;
}

.dialog .top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
}

.dialog .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);

  transition: background-color 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog .close:hover {
  background-color: $red;
}

.dialog .close svg {
  fill: $white;
  width: 12px;
  height: 12px;
}

.loader {
  display: flex;
  width: 24px;
  height: 40%;
}

.loader div {
  display: block;
  height: 50%;
  width: 20%;
  background: #fff;
  animation: loader-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader div:nth-child(1) {
  margin-right: 20%;
  animation-delay: -0.24s;
}

.loader div:nth-child(2) {
  margin-right: 20%;
  animation-delay: -0.12s;
}

.loader div:nth-child(3) {
  animation-delay: 0;
}

@keyframes loader-animation {
  0% {
    transform: translateY(-25%);
    height: 200%;
  }
  50%,
  100% {
    transform: translateY(-0%);
    height: 100%;
  }
}
</style>
