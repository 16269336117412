
import { defineComponent } from "vue";
import { IDropdownItem } from "../../models/DropdownItem";

export default defineComponent({
  name: "Dropdown",
  props: {
    placeholder: {
      type: String,
      default: "Select..."
    },
    items: Array as () => IDropdownItem[],
    label: String,
    classes: Array,
    initialSelection: Number,
    modelValue: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      isExtended: false,
      hasFocus: false,
    };
  },
  methods: {
    setState: function (value: boolean) {
        this.hasFocus = value;
    },
    toggle: function() {
      this.hasFocus = !this.hasFocus;
    },
    select: function (id: string) {
      this.hasFocus = false;
      this.$emit("update:modelValue", id);
    },
  },
  computed: {
    selectedText: function (): string {
      let item = this.items!.find((o) => o.id == this.modelValue);

      if (item !== undefined) return item!.label;
      else return "";
    },
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.modelValue != "";
    },
  },
  mounted: function () {
    if (this.initialSelection !== undefined)
      this.select(this.items![this.initialSelection!].id);
  },
});
