
import { defineComponent } from "vue";
import Row from "../components/table/Row.vue";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import { IRole } from "../models/Role";
import TabView from "../components/tab-view/TabView.vue";
import { IOrder } from "../models/IOrder";
import { IOrderDTO } from "../models/IOrderDTO";
import { DateFormatting } from "../mixins/DateFormatting";
import OrderTable from "../components/orders/OrderTable.vue";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import { DateTime } from "luxon";

export default defineComponent({
  name: "Orders",
  mixins: [DateFormatting],
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    WarningDialog,
    TabView,
    OrderTable,
    TableView,
    SkeletonRow
  },
  data: function () {
    return {
      isLoading: false,
      tabLabels: [
        { tab: "orders", label: "Orders" },
        { tab: "archive", label: "Archief" },
      ],
      activeTab: "orders",
      orderTab: {
        orders: [] as IOrder[],
        pageIndex: 0,
        pageSize: 25,
        totalSize: 0,
      },
      archiveTab: {
        orders: [] as IOrder[],
        pageIndex: 0,
        pageSize: 25,
        totalSize: 0,
      },
      orderOverview: {
        isOpen: false,
        finishable: false,
        order: {} as IOrder,
      },
      isFinishingOrder: false,
      notification: "",
      autoCompleteList: [],
      addErrorMessage: "",
      editErrorMessage: "",
      promptForRemoval: false,
      query: "",
      autoCompleteLoading: false,
    };
  },
  methods: {
    openFinishableOrder: function (order: IOrder) {
      this.orderOverview.isOpen = true;
      this.orderOverview.finishable = true;
      this.orderOverview.order = order;
    },
    openArchivedOrder: function (order: IOrder) {
      this.orderOverview.isOpen = true;
      this.orderOverview.finishable = false;
      this.orderOverview.order = order;
    },
    closeOrder: function () {
      this.orderOverview.isOpen = false;
      this.orderOverview.finishable = false;
      this.orderOverview.order = {} as IOrder;
    },
    loadOrderPage: function (pageIndex: number, pageSize: number) {
      this.isLoading = true;
      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&query.finished=false";

      this.orderTab.orders.splice(0);

      return axios
        .get("/orders" + params)
        .then((response) => {

          this.orderTab.pageIndex = pageIndex;
          this.orderTab.totalSize = response.data.totalSize;

          let orders = response.data.orders;
          orders.forEach((order: IOrderDTO) => {
            let orderObj = {
              orderId: order.orderId,
              creationDate: DateTime.fromSQL(order.creationDate),
              deliveryDate: DateTime.fromSQL(order.deliveryDate),
              user: order.user,
              items: order.items,
            };

            this.orderTab.orders.push(orderObj);
          });

          this.isLoading = false;
        })
        .catch((reason) => {});
    },
    loadArchivePage: function (pageIndex: number, pageSize: number) {
      this.isLoading = true;

      this.archiveTab.orders.splice(0);

      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&query.finished=true";
      return axios
        .get("/orders" + params)
        .then((response) => {

          this.archiveTab.pageIndex = pageIndex;
          this.archiveTab.totalSize = response.data.totalSize;

          let orders = response.data.orders;
          orders.forEach((order: IOrderDTO) => {
            let orderObj = {
              orderId: order.orderId,
              creationDate: DateTime.fromSQL(order.creationDate),
              deliveryDate: DateTime.fromSQL(order.deliveryDate),
              user: order.user,
              items: order.items,
            };

            this.archiveTab.orders.push(orderObj);
          });

          this.isLoading = false;
        })
        .catch((reason) => {});
    },
    finishOrder: function () {
      this.isFinishingOrder = true;
      let orderId = this.orderOverview.order.orderId;
      axios
        .put("/orders", { orderId: orderId, isFinished: true })
        .then((response) => {
          this.closeOrder();
          this.loadOrderPage(0, this.orderTab.pageSize);
        })
        .catch((reason) => {})
        .finally(() => {
          this.isFinishingOrder = false;
        });
    },
  },
  computed: {
    overviewOrderDate: function (): string {
      return this.formatDate(this.orderOverview.order.creationDate);
    },
    overviewDeliveryDate: function (): string {
      return this.formatDate(this.orderOverview.order.deliveryDate);
    }
  },
  watch: {
    activeTab: function(value: string) {
      this.orderTab.orders = [];
      this.archiveTab.orders = [];

      if (value == "orders")
        this.loadOrderPage(0, this.orderTab.pageSize);

      if (value == "archive")
        this.loadArchivePage(0, this.archiveTab.pageSize);
    }
  },
  mounted: function () {
    this.loadOrderPage(0, 25);
  },
});
