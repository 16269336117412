<template>
    <div class="users">
        <h1>Gebruikers</h1>
        <div class="action-container">
            <button class="add-button" @click="openAddDialog">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
            </button>
            <search-field v-model="query" :autoCompleteList="autoCompleteList" :autoCompleteLoading="autoCompleteLoading" @submit="search" @partial-query="autoComplete" :filters="filters"></search-field>
        </div>
        <table-view>
            <template v-slot:columns>
                <column :width="'30%'">Naam</column>
                <column :width="'30%'">E-mail</column>
                <column :width="'20%'">Rollen</column>
                <column></column>
            </template>
            <template v-slot:rows>
                <row v-for="row in userRows" :key="row.user.id" :model="row.user" :viewableKeys="['name', 'email', 'roles.label']" :visible="row.visible" :isDeleting="row.isDeleting">
                    <template v-slot:email="{ model }">
                        <div class="email-column">
                            <svg v-if="!model.isEmailVerified" xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="email-unverified" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm-.55 8.502L7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0zM8.002 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>
                            <svg v-if="model.isEmailVerified" xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="email-verified" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                            </svg>
                            <span class="email">{{ model.email }}</span>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <row-actions @edit="openEditDialog(row.user)" @remove="requestRemove(row.user)"></row-actions>
                    </template>
                </row>
            </template>
            <template v-slot:skeleton-rows>
                <skeleton-row v-for="n in 25" :key="n" :columnNumber="4" :isVisible="isLoading"></skeleton-row>
            </template>
        </table-view>

        <pagination :totalSize="totalSize" :pageIndex="pageIndex" :pageSize="pageSize" @load-page="loadPage"></pagination>

        <user-dialog v-model:isOpen="editWindow.isOpen" :user="editWindow.user" :allRoles="allRoles" :properties="{ type: 'edit', submitText: 'Aanpassen', cancelText: 'Verwerpen' }" @submit="editUser"></user-dialog>
        <user-dialog v-model:isOpen="addWindow.isOpen" :allRoles="allRoles" :properties="{ type: 'add', submitText: 'Toevoegen', cancelText: 'Verwerpen' }" @submit="addUser"></user-dialog>
        <warning-dialog v-model:isOpen="removeDialog.isOpen" @submit="removeUser" @close="closeWarning"></warning-dialog>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import UserDialog from "../components/users/UserDialog.vue";
import Row from "../components/table/Row.vue";
import { IUser } from "../models/User";
import { IUserDTO } from "../models/UserDTO";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { IUserPrototype } from "../models/UserPrototype";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import RowActions from "../components/table/RowActions.vue";
import { IRole } from "../models/Role";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import { QueryFactory } from "../mixins/QueryFactory";

interface IUserRow {
  visible: boolean;
  isDeleting: boolean;
  user: IUser;
}

export default defineComponent({
  name: "Users",
  mixins: [QueryFactory],
  components: {
    Row,
    UserDialog,
    Pagination,
    SearchField,
    Column,
    WarningDialog,
    RowActions,
    TableView,
    SkeletonRow,
  },
  data: function () {
    return {
      notification: "",
      isLoading: false,
      editWindow: {
        isOpen: false,
        user: {} as IUser,
      },
      addWindow: {
        isOpen: false,
      },
      removeDialog: {
        isOpen: false,
      },
      filters: [
        {
          id: "Id",
          active: true,
        },
        {
          name: "Naam",
          active: true,
        },
        {
          email: "E-mail",
          active: true
        }
      ],
      allRoles: [] as IRole[],
      userRows: [] as IUserRow[],
      pageIndex: 0,
      pageSize: 25,
      totalSize: 0,
      autoCompleteList: [],
      addErrorMessage: "",
      editErrorMessage: "",
      promptForRemoval: false,
      queuedForRemoval: {} as IUser,
      query: "",
      autoCompleteLoading: false,
    };
  },
  methods: {
    addUser: function (user: IUserPrototype) {
      axios.post("/users", { user: user }).then((response) => {
        if (response.status === 201) {
          this.addWindow.isOpen = false;
          this.loadPage(this.pageIndex, this.pageSize);
        } else {
          this.addErrorMessage = "Er ging iets fout";
        }
      });
    },
    editUser: function (user: IUser) {
      axios
        .put("/users", { user: user })
        .then((response) => {
          if (response.status === 200) {
            this.loadPage(this.pageIndex, this.pageSize);
            this.editWindow.isOpen = false;
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.editErrorMessage = "Er ging iets fout";
        });
    },
    openAddDialog: function () {
      this.addWindow.isOpen = true;
    },
    openEditDialog: function (user: IUser) {
      this.editWindow.user = user;
      this.editWindow.isOpen = true;
    },
    loadRoles: function () {
      return axios
        .get("/roles")
        .then((response) => {
          this.allRoles = response.data.roles;
        })
        .catch((reason) => {
          this.$emit("notification", "Er ging iets mis bij het laden", "error");
        });
    },
    loadPage: function (pageIndex: number, pageSize: number) {
      this.isLoading = true;
      this.userRows.splice(0);

      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
      if (this.query != "") {
        params += this.createQuery("user", this.query, this.filters);
      }

      return axios
        .get("/users" + params, {})
        .then((response) => {
          if (response.status === 200) {
            this.pageIndex = pageIndex;
            this.totalSize = parseInt(response.data.totalSize);

            this.setTable(response.data.users);

            this.isLoading = false;
          }
        })
        .catch((response) => {});
    },
    requestRemove: function (user: IUser) {
      this.removeDialog.isOpen = true;
      this.queuedForRemoval = user;
    },
    removeUser: function () {
      let row = {} as IUserRow;
      this.userRows.forEach((element, index) => {
        if (element.user.id === this.queuedForRemoval.id) {
          row = element;
          return false;
        }
      });

      axios
        .delete("/users", { params: { id: this.queuedForRemoval.id } })
        .then((response) => {
          row.visible = false;
          row.isDeleting = false;

          if (response.status === 204)
            this.loadPage(this.pageIndex, this.pageSize);

          if (response.status === 500)
            this.$emit("notification", "Verwijderen mislukt", "error");

          this.removeDialog.isOpen = false;
        });
    },
    setTable: function (users: IUserDTO[]) {
      this.userRows = [];
      users.forEach((element) => {
        let roles = [] as IRole[];

        element.roles.forEach((element) => {
          let roleObj = this.allRoles.find((o) => {
            return o.id == element;
          });

          roles.push(roleObj!);
        });

        let user = {
          id: element.id,
          name: element.name,
          email: element.email,
          isEmailVerified: element.isEmailVerified,
          roles: roles,
        };

        this.userRows.push({
          visible: false,
          isDeleting: false,
          user: user,
        });
      });

      let index = 0;
      let setUserCallback = () => {
        if (users.length != 0 && index < users.length) {
          this.userRows[index++].visible = true;
          setTimeout(() => {
            setUserCallback();
          }, 30);
        }
      };

      setUserCallback();
    },
    search: function () {
      console.log("search " + this.query);
      this.autoCompleteList = [];
      this.loadPage(0, this.pageSize);
    },
    autoComplete: function (query: string) {
      this.autoCompleteList = [];

      if (query !== "") {
        this.autoCompleteLoading = true;
        axios
          .get("/users/suggestions", { params: { query: query } })
          .then((response) => {
            this.autoCompleteList = response.data.suggestions;
            this.autoCompleteLoading = false;
          })
          .catch(() => {
            this.autoCompleteLoading = false;
          });
      }
    },
    closeWarning: function () {
      this.promptForRemoval = false;
    },
  },
  mounted() {
    this.isLoading = true;

    this.loadRoles().then(() => {
      this.loadPage(0, this.pageSize).finally(() => {
        this.isLoading = false;
      });
    });
  },
});
</script>

<style lang="scss">
@import "../scss/global.scss";

.action-container {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}

.tag {
  background-color: $lighter-blue;
  padding: 5px;
  margin: 2px;
}
</style>

<style scoped lang="scss">
@import "../scss/global.scss";

.email-column {
  display: flex;
  align-items: center;
}

span.email {
  display: inline-block;
  margin-left: 5px;
}

.email-verified {
  display: inline-block;
  fill: $green;
}

.email-unverified {
  display: inline-block;
  fill: $orange;
}

.users {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
}

.title-container .loader {
  margin-left: 15px;
  width: 24px;
  height: 24px;
}
</style>
