<template>
    <div class="main">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import GlobalMessage from "../components/GlobalMessage.vue";
import SideNavigation from "../components/SideNavigation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultLayout",
  components: {
      GlobalMessage,
      SideNavigation
  }
});
</script>

<style lang="scss" scoped>
</style>
