
import { defineComponent } from "vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import axios from "axios";

export default defineComponent({
  name: "SubmitPasswordReset",
  components: {
    PasswordInput,
  },
  data: function () {
    return {
      password: "",
      message: "",
      messageClass: "",
      pwdErrorMsg: "",
      hasSuccess: false,
    };
  },
  methods: {
    submit: function () {
      if (this.$route.query.verify !== undefined) {
        axios
          .post("/password-reset/submit-verify", {
            resetToken: {
                token: this.$route.query.reset
            },
            verifyToken: {
                token: this.$route.query.verify
            },
            password: this.password
          })
          .then(() => {
            this.hasSuccess = true;
          })
          .catch((reason) => {
            this.message = "Er is een fout opgetreden.";
            this.messageClass = "error";

            if (reason.response.status == 400 && reason.response.data.invalidPassword)
                this.pwdErrorMsg = "Ongeldig wachtwoord";
          });
      } else {
        axios
          .post("/password-reset/submit", {
            token: this.$route.query.token,
            password: this.password,
          })
          .then(() => {
            this.hasSuccess = true;
          })
          .catch(() => {
            this.message = "Er is een onbekende fout opgetreden.";
            this.messageClass = "error";
          });
      }
    },
  },
  computed: {
    hasMessage: function (): boolean {
      return this.message !== "";
    },
  },
});
