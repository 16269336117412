
import { defineComponent } from "vue";

export default defineComponent({
  name: "Slider",
  props: {
    label: {
      type: String
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      state: false
    };
  },
  methods: {
    toggle: function () {
      this.state = !this.state;
      this.$emit("update:modelValue", this.state);
    }
  },
  watch: {
      modelValue: function() 
      {
          this.state = this.modelValue;
      }
  }
});
