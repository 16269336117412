<template>
    <div class="loading-container" v-bind:class="[customClass]" v-bind:style="{ top: top }">
        <div class="loading" v-if="showLoadingIcon">
            <div class="loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <p class="message">{{message}}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "GlobalMessage",
  props: {
    message: String,
    customClass: String,
    showLoadingIcon: Boolean
  },
  data() {
    return {
      top: "-50px",
    };
  },
  watch: {
    message: function () {
      if (this.message === "" || this.message === undefined) this.top = "-50px";
      else this.top = "0px";
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: $blue;
  z-index: 500;
  display: flex;
  align-items: center;

  transition: top 1s;
  padding: 0px 20px;
  box-sizing: border-box;
}

.loading-container .message {
  font-size: 1.2em;
  color: $white;
  margin: 0;
}

.loading-container.success {
  background-color: $green;
}

.loading-container.error {
  background-color: $red;
}

.loading {
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

</style>