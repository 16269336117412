<template>
    <div class="overlay" v-bind:class="isOpen ? 'open' : 'closed'">

        <div class="dialog" :class="properties.type">
            <div class="top-bar">
                <button class="close" v-on:click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                    </svg>
                </button>
            </div>

            <div class="content">

                <text-input class="name-input" :label="'Naam'" v-model="internalUser.name" :errorMsg="nameError"></text-input>
                <text-input class="email-input" :label="'E-mail'" v-model="internalUser.email" :errorMsg="emailError"></text-input>

                <role-input ref="roleInput" v-on:roles-changed="rolesChanged" :allRoles="allRoles" :currentActiveRoles="internalUser.roles"></role-input>

                <dialog-actions :submitText="properties.submitText" :cancelText="properties.cancelText" :isLoading="isLoading" :errorMessage="errorMessage" @submit="submit" @cancel="close"></dialog-actions>
            </div>
        </div>

    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import { IUser } from '../../models/User';
import RoleInput from '../input/RoleInput.vue';
import { IRole } from '../../models/Role';
import TextInput from '../input/TextInput.vue';
import { IUserPrototype } from "../../models/UserPrototype";
import DialogActions from '../dialog/DialogActions.vue';
import { IDialogProperties } from "../../models/DialogProperties";
import { required, email, between, or, sameAs, url, minValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default defineComponent({
    name: "UserDialog",
    setup() {
        return { v$: useVuelidate() }
    },
    components: {
        DialogActions,
        TextInput,
        RoleInput
    },
    props: {
        properties: Object as () => IDialogProperties,
        isOpen: {
            type: Boolean
        },
        user: {
            type: Object as () => IUser,
            required: true
        },
        allRoles: Array as () => IRole[],
        errorMessage: String
    },
    data: function () {
        return {
            isLoading: false,
            internalUser: { id: "", name: "", email: "", isEmailVerified: false, roles: [] } as IUser,
        }
    },
    methods: {
        open: function() {
            this.$emit("update:isOpen", true);
        },

        close: function () {
            this.$emit("update:isOpen", false);
        },

        submit: function() {
            this.v$.$touch();
            if (!this.v$.$error) {
                this.isLoading = true;
                this.$emit("submit", this.internalUser);
            }
        },

        rolesChanged: function(roles: IRole[]) {
            this.internalUser.roles = roles;
        }
    },
    validations() {
        return {
            internalUser: {
                name: { required },
                email: { required, email },
                roles: { required }
            }
        }
    },
    computed: {
        nameError: function(): string {
            return (this.v$ as any).internalUser.name.$error ? "Ongeldige naam" : "";
        },
        emailError: function(): string {
            return (this.v$ as any).internalUser.email.$error ? "Ongeldige email" : "";
        }
    },
    watch: {
        user: function() {
            this.internalUser.id = this.user!.id ? this.user!.id : "";
            this.internalUser.name = this.user!.name ? this.user!.name : "";
            this.internalUser.email = this.user!.email ? this.user!.email : "";
            this.internalUser.roles = this.user!.roles ? this.user!.roles : [];
        },
        isOpen: function() {
            if (!this.isOpen)
                this.isLoading = false;
        }
    }
});
</script>

<style lang="scss" scoped>

@import '../../scss/global.scss';

.dialog.add {
    border-color: $green;
}

.dialog.edit {
    border-color: $orange;
}

.name-input {
    margin-bottom: 15px;
}

.email-input {
    margin-bottom: 15px;
}

:deep(.dialog.add .submit) {
    border-color: $green;
    color: $green;
}

:deep(.dialog.add .submit:hover) {
    background-color: $green;
    color: $white;
}

:deep(.dialog.add .submit:active) {
    transition: background-color 
}

:deep(.dialog.add .loading) {
    background-color: $green;
}

:deep(.cancel) {
    border-color: $red;
    color: $red;
}

:deep(.cancel:hover) {
    background-color: $red;
    color: $white;
}

:deep(.dialog.edit .submit) {
    border-color: $orange;
    color: $orange;
}

:deep(.dialog.edit .submit:hover) {
    background-color: $orange;
    color: $white;
}

:deep(.dialog.edit .loading) {
    background-color: $orange;
}

</style>
