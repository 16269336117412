<template>
    <div class="actions">
        <div class="loading" v-if="isLoading">
            <div class="loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p>Aan het laden</p>
        </div>
        <div class="error" v-if="hasError">
            <p>{{ errorMessage }}</p>
            <button class="retry" @click="retry">Opnieuw</button>
        </div>
        <button class="hollow-button green submit" v-show="hasSubmitText" @click="submit">{{submitText}}</button>
        <button class="hollow-button red" v-show="hasCancelText" @click="cancel">{{cancelText}}</button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "DialogActions",
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        errorMessage: String,
        submitText: {
            type: String,
            default: "Submit"
        },
        cancelText: {
            type: String,
            default: "Cancel"
        }
    },
    methods: {
        submit: function() {
            this.$emit("submit");
        },
        cancel: function() {
            this.$emit("cancel");
        },
        retry: function() {
            this.$emit("retry");
        }
    },
    computed: {
        hasError: function(): boolean {
            return this.errorMessage !== "" && this.errorMessage !== null && this.errorMessage !== undefined;
        },
        hasSubmitText: function(): boolean {
            return this.submitText !== "" && this.submitText !== undefined;
        },
        hasCancelText: function(): boolean {
            return this.cancelText !== "" && this.cancelText !== undefined;
        }
    }
})
</script>

<style lang="scss" scoped>

@import "../../scss/global.scss";

.actions {
    position: relative;
    display: flex;
}

.loading {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.loading p {
    margin: 0px 0px 0px 10px;
}

.error {
    display: flex;
    align-items: center;
    position: absolute;
    background-color: $red;
    width: 100%;
    height: 100%;
    padding: 0px 15px;
}

.error p {
    margin: 0;
}

.error .retry {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    color: $white;
    border-color: $white;
}

.error .retry:hover {
    background-color: $white;
    color: $red;
}

.submit {
    margin-right: 5px;
}

</style>
