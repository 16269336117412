<template>
    <div class="sidebar-layout">
        <side-navigation :account="account"></side-navigation>
        <div class="main">
            <global-message :message="message" :customClass="messageClass" :showLoadingIcon="showLoadingIcon"></global-message>
            <slot :showMessage="showMessage"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import GlobalMessage from "../components/GlobalMessage.vue";
import SideNavigation from "../components/SideNavigation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SidebarLayout",
  components: {
    GlobalMessage,
    SideNavigation,
  },
  props: {
    account: Object
  },
  data: function () {
    return {
      message: "",
      messageClass: "",
      showLoadingIcon: true
    };
  },
  methods: {
    showMessage(message: string, messageClass: string, showLoadingIcon?: boolean) {
      this.message = message;
      this.messageClass = messageClass;
      this.showLoadingIcon = showLoadingIcon ? showLoadingIcon : true;
    },
    hideMessage() {
      this.message = "";
      this.messageClass = "";
    },
  },
});
</script>

<style lang="scss" scoped>

.sidebar-layout {
  display: grid;
  height: 100%;
  max-height: 100vh;
  grid-template-columns: 50px auto;
  grid-template-rows: 100%;
}

</style>