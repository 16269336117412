

import { defineComponent } from 'vue';
import { IUser } from '../../models/User';
import RoleInput from '../input/RoleInput.vue';
import { IRole } from '../../models/Role';
import TextInput from '../input/TextInput.vue';
import { IUserPrototype } from "../../models/UserPrototype";
import DialogActions from '../dialog/DialogActions.vue';
import { IDialogProperties } from "../../models/DialogProperties";
import { required, email, between, or, sameAs, url, minValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default defineComponent({
    name: "UserDialog",
    setup() {
        return { v$: useVuelidate() }
    },
    components: {
        DialogActions,
        TextInput,
        RoleInput
    },
    props: {
        properties: Object as () => IDialogProperties,
        isOpen: {
            type: Boolean
        },
        user: {
            type: Object as () => IUser,
            required: true
        },
        allRoles: Array as () => IRole[],
        errorMessage: String
    },
    data: function () {
        return {
            isLoading: false,
            internalUser: { id: "", name: "", email: "", isEmailVerified: false, roles: [] } as IUser,
        }
    },
    methods: {
        open: function() {
            this.$emit("update:isOpen", true);
        },

        close: function () {
            this.$emit("update:isOpen", false);
        },

        submit: function() {
            this.v$.$touch();
            if (!this.v$.$error) {
                this.isLoading = true;
                this.$emit("submit", this.internalUser);
            }
        },

        rolesChanged: function(roles: IRole[]) {
            this.internalUser.roles = roles;
        }
    },
    validations() {
        return {
            internalUser: {
                name: { required },
                email: { required, email },
                roles: { required }
            }
        }
    },
    computed: {
        nameError: function(): string {
            return (this.v$ as any).internalUser.name.$error ? "Ongeldige naam" : "";
        },
        emailError: function(): string {
            return (this.v$ as any).internalUser.email.$error ? "Ongeldige email" : "";
        }
    },
    watch: {
        user: function() {
            this.internalUser.id = this.user!.id ? this.user!.id : "";
            this.internalUser.name = this.user!.name ? this.user!.name : "";
            this.internalUser.email = this.user!.email ? this.user!.email : "";
            this.internalUser.roles = this.user!.roles ? this.user!.roles : [];
        },
        isOpen: function() {
            if (!this.isOpen)
                this.isLoading = false;
        }
    }
});
