<template>
    <div class="products">
        <h1>Producten</h1>
        <div class="action-container">
            <button class="add-button" @click="openAddDialog">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
            </button>
            <search-field v-model="filter.query" :autoCompleteList="autoCompleteList" @submit="loadPage(0, 25)" :filters="filters"></search-field>
        </div>
        <table-view>
            <template v-slot:columns>
                <column :width="'5%'">ID</column>
                <column :width="'30%'">Label</column>
                <column :width="'10%'">Prijs</column>
                <column :width="'10%'">Eenheid</column>
                <column :width="'5%'">Beschikbaar</column>
                <column></column>
            </template>
            <template v-slot:rows>
                <row v-for="product in productRows" :key="product.product.id" :model="product.product" :viewableKeys="['id', 'label', 'price', 'priceUnit', 'isAvailable']" :visible="product.visible" :isDeleting="product.isDeleting">
                    <template v-slot:actions>
                        <row-actions @edit="openEditDialog(product.product)" @remove="requestRemove" :settings="rowActions"></row-actions>
                    </template>
                </row>
            </template>
            <template v-slot:skeleton-rows>
                <skeleton-row v-for="n in 25" :key="n" :columnNumber="6" :isVisible="isPageLoading"></skeleton-row>
            </template>
        </table-view>

        <pagination :totalSize="totalSize" :pageIndex="pageIndex" :pageSize="pageSize" @load-page="loadPage"></pagination>

        <product-dialog v-model="addWindow.product" v-model:isLoading="addWindow.isLoading" :settings="settings" v-model:inputErrors="addWindow.inputErrors" :properties="{ type: 'add', submitText: 'Toevoegen', cancelText: 'Verwerpen' }" :categories="categories" v-model:isOpen="addWindow.isOpen" @submit="addProduct"></product-dialog>
        <product-dialog v-model="editWindow.product" v-model:isLoading="editWindow.isLoading" :settings="settings" v-model:inputErrors="editWindow.inputErrors" :properties="{ type: 'edit', submitText: 'Aanpassen', cancelText: 'Verwerpen' }" :categories="categories" v-model:isOpen="editWindow.isOpen" :product="editWindow.product" @submit="editProduct"></product-dialog>
        <warning-dialog v-model:isOpen="removeDialog.isOpen" @submit="removeProduct"></warning-dialog>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import { IProduct } from "../models/Product";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import Row from "../components/table/Row.vue";
import ProductDialog from "../components/products/ProductDialog.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import RowActions from "../components/table/RowActions.vue";
import { ICategory } from "../models/Category";
import { UrlParameterGenerator } from "../UrlParameterGenerator";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import { QueryFactory } from "../mixins/QueryFactory";

interface IProductRow {
  visible: boolean;
  isDeleting: boolean;
  product: IProduct;
}

export default defineComponent({
  name: "Products",
  mixins: [QueryFactory],
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    ProductDialog,
    WarningDialog,
    RowActions,
    TableView,
    SkeletonRow,
  },
  data: function () {
    return {
      editWindow: {
        isOpen: false,
        isLoading: false,
        product: {} as IProduct,
        inputErrors: {
          id: "",
          label: "",
        },
      },
      addWindow: {
        isOpen: false,
        isLoading: false,
        product: {
          id: 1,
          label: "",
          price: 1,
          priceUnit: "kg",
          image: "",
          isAvailable: false,
          categoryId: 5,
        } as IProduct,
        inputErrors: {
          id: "",
          label: "",
        },
      },
      removeDialog: {
        isOpen: false,
      },
      filter: {
        query: "",
      },
      filters: [
        {
          id: "Id",
          active: true
        },
        {
          label: "Label",
          active: true
        }
      ],
      productRows: [] as IProductRow[],
      rowActions: {
        hasEdit: true,
        hasRemove: false
      },
      pageIndex: 0,
      pageSize: 25,
      totalSize: 0,
      autoCompleteList: [],
      isPageLoading: false,
      promptForRemoval: false,
      queuedForRemoval: {} as IProduct,
      categories: [] as ICategory[],
      settings: {},
    };
  },
  methods: {
    addProduct: function (product: IProduct, blobUrl: string) {
      if (blobUrl != "" && blobUrl != null) {
        this.uploadImage(blobUrl).then((image: string) => {
          product.image = image;
          this.uploadProduct(product)
            .then((response) => {
              this.addWindow.isOpen = false;
              this.loadPage(this.pageIndex, this.pageSize);
            })
            .catch((reason) => {
              this.addWindow.isLoading = false;
              if (reason.response.status == 400) {
                if (reason.response.data.invalidId)
                  this.addWindow.inputErrors.id = "Id bestaat al";
              }
            });
        });
      } else {
        product.image = "placeholder.jpg";
        this.uploadProduct(product)
          .then((response) => {
            this.addWindow.isOpen = false;
            this.loadPage(this.pageIndex, this.pageSize);
          })
          .catch((reason) => {
            this.addWindow.isLoading = false;
            if (reason.response.status == 400) {
              if (reason.response.data.invalidId)
                this.addWindow.inputErrors.id = "Id bestaat al";
            }
          });
      }
    },
    editProduct: function (product: IProduct, blobUrl: string) {
      if (blobUrl !== "" && blobUrl !== null) {
        this.uploadImage(blobUrl).then((image: string) => {
          product.image = image;
          this.updateProduct(product);
        });
      } else {
        return this.updateProduct(product);
      }
    },
    uploadImage: function (blobUrl: string): Promise<string> {
      if (blobUrl !== null && blobUrl !== undefined) {
        return axios
          .get(blobUrl, { baseURL: "", responseType: "blob" })
          .then((response) => {
            let imgData = new FormData();
            imgData.append("image", response.data, "image");

            return axios
              .post("/products/image", imgData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response) => {
                return response.data.image;
              });
          });
      } else throw new Error();
    },
    uploadProduct: function (product: IProduct) {
      return axios.post("/products/", { product: product });
    },
    updateProduct: function (product: IProduct) {
      axios
        .put("/products/", { product: product })
        .then((response) => {
          this.loadPage(this.pageIndex, this.pageSize);
          this.editWindow.isOpen = false;
        })
        .catch((reason) => {
          this.$emit("notification", "Aanpassing mislukt", "");
        });
    },
    openAddDialog: function () {
      this.addWindow.isOpen = true;
      this.addWindow.product = {
        id: 1,
        label: "",
        price: 1,
        priceUnit: "kg",
        image: "placeholder.jpg",
        isAvailable: false,
        categoryId: 4,
      };
    },
    openEditDialog: function (product: IProduct) {
      this.editWindow.product = product;
      this.editWindow.isOpen = true;
    },
    loadCategories: function () {
      return axios
        .get("/products/categories")
        .then((response) => {
          this.categories = response.data.categories;
        })
        .catch((reason) => {});
    },
    loadSettings: function () {
      return axios.get("/products/settings").then((response) => {
        this.settings = response.data.settings;
      });
    },
    loadPage: function (pageIndex: number, pageSize: number) {
      this.isPageLoading = true;
      this.productRows.splice(0);

      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
      if (this.filter.query != "") {
        params += this.createQuery("product", this.filter.query, this.filters);
      }

      axios.get("/products/" + params).then((response) => {
        this.isPageLoading = false;
        this.pageIndex = pageIndex;
        this.totalSize = response.data.totalSize;

        this.setTable(response.data.products);

        this.isPageLoading = false;
      });
    },
    requestRemove: function (product: IProduct) {
      this.queuedForRemoval = product;
      this.removeDialog.isOpen = true;
    },
    removeProduct: function () {
      let row = {} as IProductRow;
      console.log(this.productRows);
      this.productRows.forEach((element, index) => {
        if (element.product.id === this.queuedForRemoval.id) {
          row = element;
          return false;
        }
      });

      axios
        .delete("/products/", { params: { id: this.queuedForRemoval.id } })
        .then((response) => {
          row.visible = false;
          row.isDeleting = false;

          this.removeDialog.isOpen = false;

          if (response.status === 204)
            this.loadPage(this.pageIndex, this.pageSize);

          if (response.status === 500)
            this.$emit("notification", "Verwijderen mislukt", "error");
        });
    },
    setTable: function (products: IProduct[]) {
      this.productRows = [];
      products.forEach((element) => {
        this.productRows.push({
          visible: false,
          isDeleting: false,
          product: element,
        });
      });

      let index = 0;
      let setProductCallback = () => {
        this.productRows[index++].visible = true;

        if (index < products.length) {
          setTimeout(() => {
            setProductCallback();
          }, 30);
        }
      };

      if (this.productRows.length > 0) setProductCallback();
    },
  },
  mounted() {
    this.isPageLoading = true;
    this.loadSettings().then(() => {
      this.loadCategories().then(() => {
        this.loadPage(0, this.pageSize);
      });
    });
  },
});
</script>

<style scoped lang="scss">
@import "../scss/global.scss";

.products {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
}

.title-container .loader {
  margin-left: 15px;
  width: 24px;
  height: 24px;
}

.add-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0px;
  color: $white;
  background-color: $green;
}

.add-product:hover {
  background-color: $light-green;
}

.add-product svg {
  display: block;
  width: 24px;
  height: 24px;
}
</style>
