
import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle: function () {
      let state = !this.modelValue;
      this.$emit("update:modelValue", state);
    }
  }
});
