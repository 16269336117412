
import { defineComponent } from "vue";

export default defineComponent({
  name: "GlobalMessage",
  props: {
    message: String,
    customClass: String,
    showLoadingIcon: Boolean
  },
  data() {
    return {
      top: "-50px",
    };
  },
  watch: {
    message: function () {
      if (this.message === "" || this.message === undefined) this.top = "-50px";
      else this.top = "0px";
    },
  },
});
