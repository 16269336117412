
import { defineComponent } from "vue";

export default defineComponent({
  name: "PasswordInput",
  props: {
    label: String,
    modelValue: {
      type: String,
      required: true
    },
    errorMsg: String,
    toggleable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      input: this.modelValue,
      errors: [] as string[],
      isEditing: false,
      isRevealing: false,
      type: "password",
      hasFocus: false,
    };
  },
  methods: {
    startEditing: function () {
      this.isEditing = true;
    },
    cancelEdit: function () {
      this.isEditing = false;
      this.$emit("update:modelValue", "");
    },
    reveal: function () {
      this.isRevealing = true;
      this.type = "text";
    },
    hide: function () {
      this.isRevealing = false;
      this.type = "password";
    },
    hasError: function () {
      return this.errorMsg !== undefined && this.errorMsg !== "";
    },
    change: function (event: Event) {
      this.$emit("update:modelValue", (event.target as any).value);
    },
    focus: function () {
      this.hasFocus = true;
    },
    unfocus: function () {
      this.hasFocus = false;
    },
  },
  computed: {
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.modelValue != "";
    },
  },
  watch: {
    modelValue: function () {
      this.input = this.modelValue;
    },
  },
});
