
import { defineComponent } from 'vue'

export default defineComponent({
    name: "Column",
    data: function() {
        return {
            mouseX: 0,
            isDragging: false
        }
    },
    props: {
        label: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: true
        }
    },
    methods: {
        startDrag: function() {
            this.isDragging = true;
        },
        drag: function(event: PointerEvent) {
            let dx = event.clientX - this.mouseX;
            this.mouseX = event.clientX;

            this.$emit("col-resize", dx);
        }
    }
});
