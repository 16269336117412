
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextInput",
  props: {
    label: String,
    modelValue: {
      type: String,
      required: true
    },
    enableError: {
      type: Boolean,
      default: true
    },
    errorMsg: String,
    toggleable: {
      type: Boolean,
      default: false
    },
    inputClasses: Array,
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      originalInput: "",
      errors: [] as string[],
      hasFocus: false,
    };
  },
  methods: {
    focus: function () {
      this.hasFocus = true;
    },
    unfocus: function () {
      this.hasFocus = false;
    },
    cancelEdit: function () {
      this.$emit("update:isEditing", false);
      this.$emit("update:modelValue", this.originalInput);

      this.$emit("modified", false);
    },
    startEditing: function () {
      this.$emit("update:isEditing", true);
      this.originalInput = this.modelValue!;

      (this.$refs.input as any).focus();
    },
    submitEdit: function () {
      this.$emit("update:isEditing", false);
      this.originalInput = "";
    },
    change: function (event: Event) {
      if ((event.target as any).value !== this.originalInput)
        this.$emit("modified", true);

      this.$emit("update:modelValue", (event.target as any).value);
      this.$emit("update:errorMsg", "");
    },
  },
  computed: {
    hasError: function (): boolean {
      return this.errorMsg !== undefined && this.errorMsg !== "";
    },
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.modelValue != "";
    },
  },
});
