
import { defineComponent } from "vue";

export default defineComponent({
  name: "Row",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    model: {
      type: Object,
      required: true
    },
    viewableKeys: {
      type: Array,
      required: true
    }
  },
  methods: {
    openEdit: function () {
      this.$emit("edit", this.model);
    },
    remove: function () {
      this.$emit("remove", this.model);
    },
    getProperty: function (key: string): any {
      let keyData = key.split(".");
      let test = this.model;

      let value = this.model![keyData[0]];

      let propertyToReturn = null;
      if (keyData.length == 2) {
        if (value.constructor === Array) {
          propertyToReturn = [];
          value.forEach((element) => {
            propertyToReturn.push(element[keyData[1]]);
          });
        } else {
          propertyToReturn = value[keyData[1]];
        }
      } else propertyToReturn = value;

      return propertyToReturn;
    },
    hasSlotContent: function (key: string) {
      return !!this.$slots[key];
    },
  },
  mounted: function () {},
  computed: {
    removalCellsToRender: function (): Array<any> {
      if (this.viewableKeys!.length > 2) return this.viewableKeys!.splice(0, 2);
      else return [];
    },
  },
});
