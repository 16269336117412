<template>
    <th :style="{ width: width }">
        <slot></slot>
        <div class="sizer" @pointerdown="startDrag" @pointermove="drag"></div>
    </th>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "Column",
    data: function() {
        return {
            mouseX: 0,
            isDragging: false
        }
    },
    props: {
        label: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: true
        }
    },
    methods: {
        startDrag: function() {
            this.isDragging = true;
        },
        drag: function(event: PointerEvent) {
            let dx = event.clientX - this.mouseX;
            this.mouseX = event.clientX;

            this.$emit("col-resize", dx);
        }
    }
});
</script>

<style lang="scss" scoped>

th {
    position: relative;
}

.sizer {
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    height: 100%;

    cursor: col-resize;
}
</style>