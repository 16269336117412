<template>
    <div class="login">
        <div class="form">
            <p class="error" :class="{ visible: hasError, invisible: !hasError }">{{ errorMessage }}</p>
            <h1>Login</h1>
            <form @submit.prevent="login">
                <text-input class="email-input" :label="'E-mail'" v-model="email"></text-input>
                <password-input class="password-input" :label="'Wachtwoord'" v-model="password"></password-input>
                <button type="submit" class="hollow-button green submit" :class="{ waiting: isLoading }">
                    <div class="loading" v-if="isLoading">
                        <div class="loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    Inloggen
                </button>
            </form>
            <router-link to="/password-reset/request" class="hollow-button blue">Wachtwoord herstellen</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from "vue";
import Vue from "vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import axios from "axios";
import { IUser } from "../models/User";

export default defineComponent({
  name: "Login",
  components: {
    TextInput,
    PasswordInput,
  },
  data: function () {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    login: function () {
      if (!this.isLoading) {
        this.isLoading = true;
        axios
          .post("/auth/login", {
            email: this.email,
            password: this.password,
          }, { withCredentials: true })
          .then((response) => {

            window.localStorage.setItem(
              "accessToken",
              response.data.accessToken
            );
            window.localStorage.setItem(
              "refreshToken",
              response.data.refreshToken
            );

            return axios.get("/account");
          })
          .then((response) => {
            let user: IUser = response.data.user as IUser;
            if (user.roles.find((item) => item.label == "admin") !== undefined)
              this.$router.push("/users");
            else this.errorMessage = "Toegang verboden";
          })
          .catch((reason) => {
            console.log(reason.response.data);

            if (reason.response.data.invalidCredentials) {
              this.errorMessage = "Ongeldige gebruikersnaam of wachtwoord.";
              return;
            }

            if (reason.response.data.accessForbidden) {
              this.errorMessage = "Toegang verboden.";
              return;
            }

            if (reason.response.data.lockedOut) {
              this.errorMessage =
                "Uw account is tijdelijk geblokkeerd, probeer binnenkort opnieuw";
              return;
            }

            this.errorMessage = "Er is een onbekende fout opgetreden.";
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    hasError: function (): boolean {
      return (
        this.errorMessage !== "" &&
        this.errorMessage !== null &&
        this.errorMessage !== undefined
      );
    },
  },
  created: function() {
    let refreshToken = window.localStorage.getItem("refreshToken");
    if (refreshToken != null) {
      axios.post("/auth/refresh", { refreshToken: refreshToken })
      .then(response => {
        window.localStorage.setItem("accessToken", response.data.accessToken);
        window.localStorage.setItem("refreshToken", response.data.refreshToken);
        this.$router.push("/users");
      })
      .catch(reason => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      });
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

h1 {
  margin: 10px 0px 25px 0px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

button,
a {
  height: 40px !important;
  width: 100%;
}

.email-input {
  width: 100%;
  margin-bottom: 15px;
}

button.submit {
  margin-top: 15px;
  margin-bottom: 10px;
}

.form {
  min-width: 300px;
  max-width: 400px;
  width: 30%;
}

.loading {
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.error {
  background-color: $red;
  line-height: 24px;
  padding: 8px;
}

.error.invisible {
  opacity: 0;
}

.error.visible {
  opacity: 1;
}

button.submit.waiting {
  background-color: $green;
  color: $white;
}
</style>