
import { defineComponent } from "vue";

export default defineComponent({
  name: "NumberInput",
  props: {
    label: String,
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    step: {
      type: Number,
      default: 1
    },
    decimals: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: Number,
      required: true
    },
    inputClasses: Array,
    useComma: {
      type: Boolean,
      default: false
    },
    errorMsg: String,
    enableError: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      hasFocus: false,
    };
  },
  methods: {
    focus: function () {
      if (!this.readonly)
        this.hasFocus = true;
    },
    unfocus: function () {
      this.hasFocus = false;
    },
    increase: function () {
      if (this.modelValue! + this.step! <= this.max!) {
        this.$emit("update:modelValue", this.modelValue! + this.step!);
        this.$emit("update:errorMsg", "");
      }
    },
    decrease: function () {
      if (this.modelValue! - this.step! >= this.min!) {
        this.$emit("update:modelValue", this.modelValue! - this.step!);
        this.$emit("update:errorMsg", "");
      }
    },
    inputChanged: function (event: InputEvent) {
      let numberInput: string = (event.target as any).value;
      numberInput = numberInput.replace(",", ".");

      let parsedInput = parseFloat(numberInput);
      if (isNaN(parsedInput)) parsedInput = this.min ? this.min : 0;

      let roundedValue = parseFloat(parsedInput.toFixed(this.decimals));
      this.$emit("update:modelValue", roundedValue);
      this.$emit("update:errorMsg", "");
    },
  },
  computed: {
    input: function (): string {
      return this.useComma
        ? this.modelValue!.toString().replace(".", ",")
        : this.modelValue!.toString();
    },
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.input != "";
    },
    hasError: function (): boolean {
      return this.errorMsg !== undefined && this.errorMsg !== "";
    },
  },
});
