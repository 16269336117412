
import { defineComponent } from "vue";
import Row from "../components/table/Row.vue";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import { DateFormatting } from "../mixins/DateFormatting";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import SectionView from "../components/SectionView.vue";
import TabView from "../components/tab-view/TabView.vue";
import Tab from "../components/tab-view/Tab.vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";

export default defineComponent({
  name: "Developer",
  mixins: [DateFormatting],
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    WarningDialog,
    TableView,
    SkeletonRow,
    SectionView,
    TabView,
    TextInput,
    PasswordInput
  },
  data: function () {
    return {
      isLoading: false,
      tabLabels: [
        { tab: "logs", label: "Logs" },
        { tab: "email-settings", label: "E-mail instellingen" },
      ],
      activeTab: "logs",
      logsTab: {
        logRows: [
            {
                isVisible: true,
                log: {
                    module: "auth"
                }
            },
            {
                isVisible: true,
                log: {
                    module: "auth"
                }
            },
            {
                isVisible: true,
                log: {
                    module: "auth"
                }
            }
        ],
      },
      emailSettingsTab: {
          reporting: {
              email: "",
              isEditing: false
          },
          sending: {
              hostInput: {
                  value: "",
                  isEditing: false
              },
              emailInput: {
                  value: "",
                  isEditing: false
              },
              passwordInput: {
                  value: "",
                  isEditing: false
              }
          },
          order: {
              emailInput: [
                  {
                      value: "",
                      isEditing: false
                  },
                  {
                      value: "",
                      isEditing: false
                  },
                  {
                      value: "",
                      isEditing: false
                  }
              ]
          }
      }
    };
  },
  methods: {
  },
  computed: {
  },
  watch: {
    activeTab: function(value: string) {
    }
  },
  mounted: function () {
  },
});
