<template>
    <div class="reset-password">
        <div v-show="!hasSuccess">
            <p class="message" :class="[{ visible: hasMessage, invisible: !hasMessage }, messageClass]">{{ message }}</p>
            <h1>Wachtwoord herstellen</h1>
            <password-input :label="'Nieuw wachtwoord'" v-model="password" :errorMsg="pwdErrorMsg"></password-input>
            <p>Minstens 8 tekens, 1 hoofdletter, 1 kleine letter en 1 cijfer</p>
            <button class="submit" @click="submit">Wachtwoord herstellen</button>
        </div>
        <div v-show="hasSuccess">
            <p class="success">Uw wachtwoord is succesvol aangepast.</p>
            <router-link to="/login" class="hollow-button blue">Naar inloggen</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import axios from "axios";

export default defineComponent({
  name: "SubmitPasswordReset",
  components: {
    PasswordInput,
  },
  data: function () {
    return {
      password: "",
      message: "",
      messageClass: "",
      pwdErrorMsg: "",
      hasSuccess: false,
    };
  },
  methods: {
    submit: function () {
      if (this.$route.query.verify !== undefined) {
        axios
          .post("/password-reset/submit-verify", {
            resetToken: {
                token: this.$route.query.reset
            },
            verifyToken: {
                token: this.$route.query.verify
            },
            password: this.password
          })
          .then(() => {
            this.hasSuccess = true;
          })
          .catch((reason) => {
            this.message = "Er is een fout opgetreden.";
            this.messageClass = "error";

            if (reason.response.status == 400 && reason.response.data.invalidPassword)
                this.pwdErrorMsg = "Ongeldig wachtwoord";
          });
      } else {
        axios
          .post("/password-reset/submit", {
            token: this.$route.query.token,
            password: this.password,
          })
          .then(() => {
            this.hasSuccess = true;
          })
          .catch(() => {
            this.message = "Er is een onbekende fout opgetreden.";
            this.messageClass = "error";
          });
      }
    },
  },
  computed: {
    hasMessage: function (): boolean {
      return this.message !== "";
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

h1 {
  margin-bottom: 25px;
}

.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

button {
  width: 100%;
  height: 40px;
  border: 2px solid;
  background-color: rgba(0, 0, 0, 0);
  font-family: inherit;
  font-weight: 600;
  margin-top: 15px;
}

button.submit {
  border-color: $green;
  color: $green;
}

button.submit:hover {
  background-color: $green;
  color: $white;
}

button.reset {
  margin-top: 10px;
  border-color: $blue;
  color: $blue;
}

button.reset:hover {
  background-color: $blue;
  color: $white;
}

.message {
  line-height: 24px;
  padding: 8px;
}

.success {
  padding: 15px;
  background-color: $green;
}

.error {
  background-color: $red;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}
</style>