<template>
    <div class="reset-password">
        <div>
            <p class="message" :class="[{ visible: hasMessage, invisible: !hasMessage }, messageClass]">{{ message }}</p>
            <h1>Wachtwoordherstel aanvragen</h1>
            <text-input :label="'E-mail'" v-model="email"></text-input>
            <button class="submit" @click="submit">Herstellink opvragen</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import axios from "axios";

export default defineComponent({
  name: "RequestPasswordReset",
  components: {
    TextInput,
    PasswordInput,
  },
  data: function() {
      return {
          email: "",
          message: "",
          messageClass: ""
      }
  },
  methods: {
      submit: function() {
          axios.post("/password-reset/request", { email: this.email })
          .then(() => {
              this.message = "Er is een e-mail verstuurd naar het opgegeven e-mailadres met verdere instructies.";
              this.messageClass = "success";
          })
          .catch(() => {
              this.message = "Er is een onbekende fout opgetreden.";
              this.messageClass = "error";
          });
      }
  },
  computed: {
      hasMessage: function(): boolean {
          return this.message !== "";
      }
  }
});
</script>

<style lang="scss" scoped>

@import "../scss/global.scss";

h1 {
    margin-bottom: 25px;
}

.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

button {
    width: 100%;
    height: 40px;
    border: 2px solid;
    background-color: rgba(0, 0, 0, 0);
    font-family: inherit;
    font-weight: 600;
    margin-top: 15px;
}

button.submit {
    border-color: $green;
    color: $green;
}

button.submit:hover {
    background-color: $green;
    color: $white;
}

button.reset {
    margin-top: 10px;
    border-color: $blue;
    color: $blue;
}

button.reset:hover {
    background-color: $blue;
    color: $white;
}

.message {
    line-height: 24px;
    padding: 8px;
}

.success {
    background-color: $green;
}

.error {
  background-color: $red;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}

</style>