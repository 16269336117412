
import { defineComponent } from "vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import { IUser } from "../models/User";
import axios from "axios";

export default defineComponent({
  name: "Account",
  components: {
    TextInput,
    PasswordInput,
  },
  data: function () {
    return {
      user: {} as IUser,
      nameInput: {
        isChanged: false,
        isEditing: false,
      },
      emailInput: {
        isChanged: false,
        isEditing: false,
      },
      password: "",
      currentPassword: "",
      isWaitingForPassword: false,
      isLoading: false,
    };
  },
  methods: {
    setNameChanged: function (value: boolean) {
      this.nameInput.isChanged = value;
    },
    setEmailChanged: function (value: boolean) {
      this.emailInput.isChanged = value;
    },
    openPasswordDialog: function () {
      this.isWaitingForPassword = true;
    },
    sendChanges: function () {
      let changes: any = {};
      if (this.nameInput.isChanged) changes["name"] = this.user.name;

      if (this.emailInput.isChanged) changes["email"] = this.user.email;

      if (this.password != "") changes["password"] = this.password;

      axios
        .put("/account", { password: this.currentPassword, change: changes })
        .then((response) => {
          this.closePasswordDialog();
          if (response.status === 400 || response.status === 500)
            this.cancelAllChanges();
          else {
            this.nameInput.isChanged = false;
            this.nameInput.isEditing = false;

            this.emailInput.isChanged = false;
            this.emailInput.isEditing = false;
          }
        });
    },
    cancelAllChanges: function () {
      (this.$refs.nameInput as any).cancelEdit();
      (this.$refs.emailInput as any).cancelEdit();
      (this.$refs.passwordInput as any).cancelEdit();
    },
    closePasswordDialog: function () {
      this.isWaitingForPassword = false;
    },
  },
  mounted() {
    this.isLoading = true;
    this.$emit("notification", "Aan het laden", "");
    axios.get("/account").then((response) => {
      this.user = response.data.user;
      this.isLoading = false;
      this.$emit("notification", "", "");
    });
  },
  computed: {
    hasChanges: function (): boolean {
      return (
        this.password !== "" ||
        this.nameInput.isChanged ||
        this.emailInput.isChanged
      );
    },
  },
});
