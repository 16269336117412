
import { defineComponent } from 'vue'

export default defineComponent({
    name: "DialogActions",
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        errorMessage: String,
        submitText: {
            type: String,
            default: "Submit"
        },
        cancelText: {
            type: String,
            default: "Cancel"
        }
    },
    methods: {
        submit: function() {
            this.$emit("submit");
        },
        cancel: function() {
            this.$emit("cancel");
        },
        retry: function() {
            this.$emit("retry");
        }
    },
    computed: {
        hasError: function(): boolean {
            return this.errorMessage !== "" && this.errorMessage !== null && this.errorMessage !== undefined;
        },
        hasSubmitText: function(): boolean {
            return this.submitText !== "" && this.submitText !== undefined;
        },
        hasCancelText: function(): boolean {
            return this.cancelText !== "" && this.cancelText !== undefined;
        }
    }
})
