
import { defineComponent } from "vue";

export default defineComponent({
  name: "SkeletonRow",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    columnNumber: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {};
  },
});
