
import { defineComponent } from "vue";
import { IProduct } from "../models/Product";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import Row from "../components/table/Row.vue";
import ProductDialog from "../components/products/ProductDialog.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import RowActions from "../components/table/RowActions.vue";
import { ICategory } from "../models/Category";
import { UrlParameterGenerator } from "../UrlParameterGenerator";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import { QueryFactory } from "../mixins/QueryFactory";

interface IProductRow {
  visible: boolean;
  isDeleting: boolean;
  product: IProduct;
}

export default defineComponent({
  name: "Products",
  mixins: [QueryFactory],
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    ProductDialog,
    WarningDialog,
    RowActions,
    TableView,
    SkeletonRow,
  },
  data: function () {
    return {
      editWindow: {
        isOpen: false,
        isLoading: false,
        product: {} as IProduct,
        inputErrors: {
          id: "",
          label: "",
        },
      },
      addWindow: {
        isOpen: false,
        isLoading: false,
        product: {
          id: 1,
          label: "",
          price: 1,
          priceUnit: "kg",
          image: "",
          isAvailable: false,
          categoryId: 5,
        } as IProduct,
        inputErrors: {
          id: "",
          label: "",
        },
      },
      removeDialog: {
        isOpen: false,
      },
      filter: {
        query: "",
      },
      filters: [
        {
          id: "Id",
          active: true
        },
        {
          label: "Label",
          active: true
        }
      ],
      productRows: [] as IProductRow[],
      rowActions: {
        hasEdit: true,
        hasRemove: false
      },
      pageIndex: 0,
      pageSize: 25,
      totalSize: 0,
      autoCompleteList: [],
      isPageLoading: false,
      promptForRemoval: false,
      queuedForRemoval: {} as IProduct,
      categories: [] as ICategory[],
      settings: {},
    };
  },
  methods: {
    addProduct: function (product: IProduct, blobUrl: string) {
      if (blobUrl != "" && blobUrl != null) {
        this.uploadImage(blobUrl).then((image: string) => {
          product.image = image;
          this.uploadProduct(product)
            .then((response) => {
              this.addWindow.isOpen = false;
              this.loadPage(this.pageIndex, this.pageSize);
            })
            .catch((reason) => {
              this.addWindow.isLoading = false;
              if (reason.response.status == 400) {
                if (reason.response.data.invalidId)
                  this.addWindow.inputErrors.id = "Id bestaat al";
              }
            });
        });
      } else {
        product.image = "placeholder.jpg";
        this.uploadProduct(product)
          .then((response) => {
            this.addWindow.isOpen = false;
            this.loadPage(this.pageIndex, this.pageSize);
          })
          .catch((reason) => {
            this.addWindow.isLoading = false;
            if (reason.response.status == 400) {
              if (reason.response.data.invalidId)
                this.addWindow.inputErrors.id = "Id bestaat al";
            }
          });
      }
    },
    editProduct: function (product: IProduct, blobUrl: string) {
      if (blobUrl !== "" && blobUrl !== null) {
        this.uploadImage(blobUrl).then((image: string) => {
          product.image = image;
          this.updateProduct(product);
        });
      } else {
        return this.updateProduct(product);
      }
    },
    uploadImage: function (blobUrl: string): Promise<string> {
      if (blobUrl !== null && blobUrl !== undefined) {
        return axios
          .get(blobUrl, { baseURL: "", responseType: "blob" })
          .then((response) => {
            let imgData = new FormData();
            imgData.append("image", response.data, "image");

            return axios
              .post("/products/image", imgData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response) => {
                return response.data.image;
              });
          });
      } else throw new Error();
    },
    uploadProduct: function (product: IProduct) {
      return axios.post("/products/", { product: product });
    },
    updateProduct: function (product: IProduct) {
      axios
        .put("/products/", { product: product })
        .then((response) => {
          this.loadPage(this.pageIndex, this.pageSize);
          this.editWindow.isOpen = false;
        })
        .catch((reason) => {
          this.$emit("notification", "Aanpassing mislukt", "");
        });
    },
    openAddDialog: function () {
      this.addWindow.isOpen = true;
      this.addWindow.product = {
        id: 1,
        label: "",
        price: 1,
        priceUnit: "kg",
        image: "placeholder.jpg",
        isAvailable: false,
        categoryId: 4,
      };
    },
    openEditDialog: function (product: IProduct) {
      this.editWindow.product = product;
      this.editWindow.isOpen = true;
    },
    loadCategories: function () {
      return axios
        .get("/products/categories")
        .then((response) => {
          this.categories = response.data.categories;
        })
        .catch((reason) => {});
    },
    loadSettings: function () {
      return axios.get("/products/settings").then((response) => {
        this.settings = response.data.settings;
      });
    },
    loadPage: function (pageIndex: number, pageSize: number) {
      this.isPageLoading = true;
      this.productRows.splice(0);

      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
      if (this.filter.query != "") {
        params += this.createQuery("product", this.filter.query, this.filters);
      }

      axios.get("/products/" + params).then((response) => {
        this.isPageLoading = false;
        this.pageIndex = pageIndex;
        this.totalSize = response.data.totalSize;

        this.setTable(response.data.products);

        this.isPageLoading = false;
      });
    },
    requestRemove: function (product: IProduct) {
      this.queuedForRemoval = product;
      this.removeDialog.isOpen = true;
    },
    removeProduct: function () {
      let row = {} as IProductRow;
      console.log(this.productRows);
      this.productRows.forEach((element, index) => {
        if (element.product.id === this.queuedForRemoval.id) {
          row = element;
          return false;
        }
      });

      axios
        .delete("/products/", { params: { id: this.queuedForRemoval.id } })
        .then((response) => {
          row.visible = false;
          row.isDeleting = false;

          this.removeDialog.isOpen = false;

          if (response.status === 204)
            this.loadPage(this.pageIndex, this.pageSize);

          if (response.status === 500)
            this.$emit("notification", "Verwijderen mislukt", "error");
        });
    },
    setTable: function (products: IProduct[]) {
      this.productRows = [];
      products.forEach((element) => {
        this.productRows.push({
          visible: false,
          isDeleting: false,
          product: element,
        });
      });

      let index = 0;
      let setProductCallback = () => {
        this.productRows[index++].visible = true;

        if (index < products.length) {
          setTimeout(() => {
            setProductCallback();
          }, 30);
        }
      };

      if (this.productRows.length > 0) setProductCallback();
    },
  },
  mounted() {
    this.isPageLoading = true;
    this.loadSettings().then(() => {
      this.loadCategories().then(() => {
        this.loadPage(0, this.pageSize);
      });
    });
  },
});
