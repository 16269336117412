
import { defineComponent } from "vue";
import { IProduct } from "../models/Product";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import Row from "../components/table/Row.vue";
import ProductDialog from "../components/products/ProductDialog.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import Checkbox from "../components/input/Slider.vue";
import TextInput from "../components/input/TextInput.vue";
import ImageInput from "@/components/input/ImageInput.vue";
import { ICustomAxiosRequestConfig } from "@/models/ICustomAxiosRequestConfig";

interface IProductRow {
  visible: boolean;
  isDeleting: boolean;
  product: IProduct;
}

export default defineComponent({
  name: "Website",
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    ProductDialog,
    WarningDialog,
    Checkbox,
    TextInput,
    ImageInput,
  },
  data: function () {
    return {
      maintenanceInput: {
        value: false,
      },
      headerImageInput: {
        placeholder: axios.defaults.baseURL + "/img/header-placeholder.jpg",
        inputData: {
          img: "",
          isChanged: false
        },
        settings: {
          width: 660,
          height: 260,
          stencilWidth: 640,
          stencilHeight: 240,
          aspectRatio: 8 / 3,
          resultWidth: 1920,
          resultHeight: 720,
        },
      },
      messageInput: {
        message: "",
        isChanged: false,
        isEditing: false,
      },
      isPageLoading: false,
    };
  },
  methods: {
    setMessageChanged: function (value: boolean) {
      this.messageInput.isChanged = value;
    },
    uploadHeaderImage: function () {
      if (
        this.headerImageInput.inputData.img &&
        this.headerImageInput.inputData.img !== ""
      ) {
        console.log("uploadin " + this.headerImageInput.inputData.img);
        return axios
          .get(this.headerImageInput.inputData.img, {
            baseURL: "",
            responseType: "blob",
          })
          .then((response) => {
            console.log("BLOG CONVERSION " + response.data);
            let imgData = new FormData();
            imgData.append("image", response.data, "image");

            return axios
              .post("/website/header-image", imgData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response) => {
                return response.data.image;
              });
          });
      } else throw new Error();
    },
    updateMessage: function () {
      axios
        .put("/website/message", { message: this.messageInput.message })
        .then((response) => {
          this.messageInput.isEditing = false;
          this.messageInput.isChanged = false;
        });
    },
    changeMaintenance: function (newValue: boolean) {
      axios
        .put("/website/maintenance", { isMaintenance: newValue })
        .then((response) => {});
    },
  },
  mounted: function () {
    this.isPageLoading = true;
    this.$emit("notification", "Aan het laden");
    axios
      .get("/website/message")
      .then((response) => {
        this.messageInput.message = response.data.message;
        return axios.get("/website/maintenance");
      })
      .then((response) => {
          this.maintenanceInput.value = response.data.maintenance;
          this.$watch("maintenanceInput.value", this.changeMaintenance);
          return axios.get("/img/header.jpg",
          {
            responseType: "arraybuffer",
            customSettings: { useAuth: false },
          } as ICustomAxiosRequestConfig)
      })
      .then((response) => {

          console.log("Received image");
          var arrayBufferView = new Uint8Array(response.data);
          var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(blob);
          this.headerImageInput.inputData.img = imageUrl;

          this.isPageLoading = false;
          this.$emit("notification", "");
      })
      .catch(() => {
        this.$emit("notification", "Fout");
      });
  },
});
