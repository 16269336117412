<template>
    <div class="tab-view">
        <ul class="header">
            <li v-for="label in labels" :key="label.tab">
                <button class="tab" :class="{ selected: activeTab == label.tab }" @click="selectTab(label.tab)">{{ label.label }}</button>
            </li>
        </ul>
        <div class="content">
            <div class="tab-content" v-for="label in labels" :key="label.tab" v-show="label.tab == activeTab">
                <slot :name="label.tab"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TabView",
  setup() {},
  props: {
    labels: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  methods: {
    selectTab: function (label: string) {
      this.$emit("update:activeTab", label);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

ul {
  display: flex;
  list-style-type: none;
  height: 40px;
  margin-bottom: 10px;
}

ul li {
  display: inline-block;
  height: 100%;
  margin-right: 5px;
  border-bottom: 2px solid $blue;
}

.tab {
  background-color: rgba(0, 0, 0, 0);
  color: $white;
  height: 100%;
  padding: 0px 20px;
  font-size: 1em;
  transition: background-color 0.2s, color 0.2s;
}

.tab:hover {
  background-color: $light-blue;
}

.tab.selected {
  background-color: $light-blue;
}

.tab-content {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.tab-view {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  position: relative;
  height: 100%;
}

</style>
