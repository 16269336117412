
import { defineComponent } from "vue";

export default defineComponent({
  name: "TabView",
  setup() {},
  props: {
    labels: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  methods: {
    selectTab: function (label: string) {
      this.$emit("update:activeTab", label);
    },
  },
});
