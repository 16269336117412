
import { defineComponent } from "vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import axios from "axios";

export default defineComponent({
  name: "RequestPasswordReset",
  components: {
    TextInput,
    PasswordInput,
  },
  data: function() {
      return {
          email: "",
          message: "",
          messageClass: ""
      }
  },
  methods: {
      submit: function() {
          axios.post("/password-reset/request", { email: this.email })
          .then(() => {
              this.message = "Er is een e-mail verstuurd naar het opgegeven e-mailadres met verdere instructies.";
              this.messageClass = "success";
          })
          .catch(() => {
              this.message = "Er is een onbekende fout opgetreden.";
              this.messageClass = "error";
          });
      }
  },
  computed: {
      hasMessage: function(): boolean {
          return this.message !== "";
      }
  }
});
