<template>
    <transition name="fade" appear>
        <tr v-if="visible">
            <template v-for="key in viewableKeys" :key="key">
                <td>
                    <slot :name="key" :model="model">
                        <template v-if="typeof getProperty(key) && getProperty(key).constructor === Array">
                            <ul>
                                <li class="tag" v-for="item in getProperty(key)" :key="item">{{ item }}</li>
                            </ul>
                        </template>
                        <template v-else-if="typeof getProperty(key) === 'boolean'">
                            <div v-show="getProperty(key)" class="boolean-container true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                </svg>
                            </div>
                            <div v-show="!getProperty(key)" class="boolean-container false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </template>
                        <template v-else>
                            {{ getProperty(key) }}
                        </template>
                    </slot>
                </td>
            </template>
            <td>
              <slot name="actions"></slot>
            </td>
        </tr>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Row",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    model: {
      type: Object,
      required: true
    },
    viewableKeys: {
      type: Array,
      required: true
    }
  },
  methods: {
    openEdit: function () {
      this.$emit("edit", this.model);
    },
    remove: function () {
      this.$emit("remove", this.model);
    },
    getProperty: function (key: string): any {
      let keyData = key.split(".");
      let test = this.model;

      let value = this.model![keyData[0]];

      let propertyToReturn = null;
      if (keyData.length == 2) {
        if (value.constructor === Array) {
          propertyToReturn = [];
          value.forEach((element) => {
            propertyToReturn.push(element[keyData[1]]);
          });
        } else {
          propertyToReturn = value[keyData[1]];
        }
      } else propertyToReturn = value;

      return propertyToReturn;
    },
    hasSlotContent: function (key: string) {
      return !!this.$slots[key];
    },
  },
  mounted: function () {},
  computed: {
    removalCellsToRender: function (): Array<any> {
      if (this.viewableKeys!.length > 2) return this.viewableKeys!.splice(0, 2);
      else return [];
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

tr {
  background-color: $blue;
  transition: background-color 0.2s;
}

tr:hover {
  background-color: $light-blue;
}

tr td {
  height: 48px;
  padding-left: 10px;
}

.loading {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: $red;
}

ul {
  display: flex;
}

.boolean-container {
  width: 32px;
  height: 32px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boolean-container.true {
  background-color: $green;
}

.boolean-container.false {
  background-color: $red;
}
</style>
