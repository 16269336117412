
import { defineComponent } from "vue";
import TextInput from "./input/TextInput.vue";
import Checkbox from "./input/Checkbox.vue";

export default defineComponent({
  name: "SearchField",
  components: {
    TextInput,
    Checkbox
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    autoCompleteList: {
      type: Array,
      default: []
    },
    autoCompleteLoading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: []
    }
  },
  data: function () {
    return {
      hasFocus: false,
      isFilterVisible: false
    };
  },
  methods: {
    submitQuery: function () {
      this.$emit("submit");
    },
    change: function (value: string) {
      this.$emit("update:modelValue", value);
    },
    focusChange: function(focus: boolean) {
      this.hasFocus = focus;
    },
    selectAutoComplete: function(value: string) {
      this.$emit("submit", value);
    },
    toggleFilter: function() {
      this.isFilterVisible = !this.isFilterVisible;
    }
  },
  watch: {
      autoCompleteLoading: function() {
      },
      autoCompleteList: function(list) {
      }
  }
});
