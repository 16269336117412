<template>
    <div class="image-input">
        <div class="first-row">
            <cropper ref="cropper" :style="{ width: settings.width + 'px', height: settings.height + 'px' }" :src="internalSource" :canvas="{ width: settings.resultWidth, height: settings.resultHeight }" :stencil-props="{ handlers: {}, movable: false, scalable: false, aspectRatio: settings.aspectRatio }" :stencil-size="{ width: settings.stencilWidth, height: settings.stencilHeight }" :move-image="isEditing" :resize-image="{ touch: isEditing, wheel: isEditing, adjustStencil: false }" :image-restriction="stencil" @change="changeWhileUpload" />
            <input ref="input" type="file" class="file-input" @change="loadImage">
            <div v-show="!isEditing" class="interaction-buttons">
                <button class="edit" @click="startEditing">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                </button>
                <button class="remove" @click="removeImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>
                <button class="upload" @click="chooseFile">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
                        <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
                    </svg>
                </button>
            </div>
            <div v-show="isEditing" class="edit-buttons">
                <button class="apply" @click="applyModification">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                </button>
                <button class="revert" @click="stopEditing">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import IImageInputSettings from "@/models/input/IImageInputSettings";
import { defineComponent } from "vue";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import IImageInputData from "../../models/input/IImageInputData";

export default defineComponent({
  name: "ImageInput",
  components: {
    Cropper,
  },
  props: {
    placeholder: String,
    modelValue: {
      type: Object as () => IImageInputData,
      required: true
    },
    settings: {
      type: Object as () => IImageInputSettings
    }
  },
  data: function () {
    return {
      internalSource: "",
      isEditing: false,
      loadingNewImage: false
    };
  },
  methods: {
    chooseFile: function () {
      (this.$refs.input as any).click();
    },
    startEditing: function () {
      this.isEditing = true;
    },
    stopEditing: function () {
      this.isEditing = false;
      (this.$refs.cropper as any).reset();
    },
    applyModification: function () {
      let { canvas } = (this.$refs.cropper as any).getResult();
      if (canvas) {
        canvas.toBlob((o: Blob) => {
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(o);
          this.$emit("update:modelValue", { img: imageUrl, isChanged: true });

          this.isEditing = false;
        });
      }
    },
    changeWhileUpload: function(obj: any) {
      if (this.loadingNewImage) {
        this.applyModification();
        this.loadingNewImage = false;
      }
    },
    loadImage: function (event: Event) {
      let files = (event.target as HTMLInputElement).files!;

      if (files.length > 0) {
        this.loadingNewImage = true;
        this.internalSource = URL.createObjectURL(files[0]);
      }
    },
    removeImage: function () {
        this.$emit("update:modelValue", { img: "", isChanged: true });
    },
  },
  watch: {
      "modelValue.img": function(newValue: any) {
          this.internalSource = newValue;
          if ((this.modelValue as any).img == "")
            this.internalSource = this.placeholder!;
      }
  }
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.first-row {
  display: flex;
}

.interaction-buttons,
.edit-buttons {
  display: flex;
  flex-direction: column;
}

.interaction-buttons button,
.edit-buttons button {
  width: 32px;
  height: 32px;
}

.choose-image {
  padding: 0px 10px;
  color: $white;
  background-color: $blue;
  font-family: inherit;
  font-weight: 300;
  height: 32px;
}

.choose-image:hover {
  background-color: $light-blue;
}

.file-input {
  display: none;
}

button.edit {
  color: $white;
  background-color: $orange;
  margin-bottom: 5px;
}

button.edit:hover {
  background-color: $light-orange;
}

button.remove {
  color: $white;
  background-color: $red;
  margin-bottom: 5px;
}

button.remove:hover {
  background-color: $light-red;
}

button.upload {
  color: $white;
  background-color: $blue;
}

button.upload:hover {
  background-color: $light-blue;
}

button.apply {
  color: $white;
  background-color: $green;
  margin-bottom: 5px;
}

button.revert {
  color: $white;
  background-color: $red;
}
</style>