<template>
    <div class="orders">
        <h1>Bestellingen</h1>
        <tab-view :labels="tabLabels" v-model:activeTab="activeTab" v-show="!orderOverview.isOpen">
            <template v-slot:orders>
                <order-table v-model="orderTab.orders" :isLoading="isLoading" @openOrder="openFinishableOrder"></order-table>
                <pagination :totalSize="orderTab.totalSize" :pageIndex="orderTab.pageIndex" :pageSize="orderTab.pageSize" @load-page="loadOrderPage"></pagination>
            </template>
            <template v-slot:archive>
                <order-table v-model="archiveTab.orders" :isLoading="isLoading" @openOrder="openArchivedOrder"></order-table>
                <pagination :totalSize="archiveTab.totalSize" :pageIndex="archiveTab.pageIndex" :pageSize="archiveTab.pageSize" @load-page="loadArchivePage"></pagination>
            </template>
        </tab-view>
        <template v-if="orderOverview.isOpen">
            <div class="order-actions">
                <button class="hollow-button lighter-blue back" @click="closeOrder">
                  Terug naar overzicht
                </button>
                <button v-if="orderOverview.finishable" class="hollow-button green finish" :class="{ 'finish-loading': isFinishingOrder }" @click="finishOrder">
                  <div class="loading" v-if="isFinishingOrder">
                      <div class="loader">
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                  </div>
                  Afhandelen
                </button>
            </div>

            <div class="info">
                <p class="order-number">Order {{ orderOverview.order.id }}</p>
                <p>Klant: {{ orderOverview.order.user.name }} (<a class="mail" :href=" 'mailto:' +  orderOverview.order.user.email">{{ orderOverview.order.user.email }}</a>)</p>
                <p>Orderdatum: {{ overviewOrderDate }}</p>
                <p>Afhaaldatum: {{ overviewDeliveryDate }}</p>
            </div>

            <table-view>
              <template v-slot:columns>
                  <column :width="'10%'">Product Id</column>
                  <column :width="'30%'">Label</column>
                  <column :width="'20%'">Hoeveelheid</column>
                  <column :width="'5%'">Eenheid</column>
                  <column></column>
              </template>

              <template v-slot:rows>
                  <row v-for="item in orderOverview.order.items" :key="item.product.id" :model="item" :viewableKeys="['product.id', 'product.label', 'quantity', 'product.priceUnit']" :visible="true"></row>
              </template>

              <template v-slot:skeleton-rows>
                  <skeleton-row v-for="n in 25" :key="n" :columnNumber="3" :isVisible="isLoading"></skeleton-row>
              </template>

            </table-view>
        </template>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import Row from "../components/table/Row.vue";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import { IRole } from "../models/Role";
import TabView from "../components/tab-view/TabView.vue";
import { IOrder } from "../models/IOrder";
import { IOrderDTO } from "../models/IOrderDTO";
import { DateFormatting } from "../mixins/DateFormatting";
import OrderTable from "../components/orders/OrderTable.vue";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import { DateTime } from "luxon";

export default defineComponent({
  name: "Orders",
  mixins: [DateFormatting],
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    WarningDialog,
    TabView,
    OrderTable,
    TableView,
    SkeletonRow
  },
  data: function () {
    return {
      isLoading: false,
      tabLabels: [
        { tab: "orders", label: "Orders" },
        { tab: "archive", label: "Archief" },
      ],
      activeTab: "orders",
      orderTab: {
        orders: [] as IOrder[],
        pageIndex: 0,
        pageSize: 25,
        totalSize: 0,
      },
      archiveTab: {
        orders: [] as IOrder[],
        pageIndex: 0,
        pageSize: 25,
        totalSize: 0,
      },
      orderOverview: {
        isOpen: false,
        finishable: false,
        order: {} as IOrder,
      },
      isFinishingOrder: false,
      notification: "",
      autoCompleteList: [],
      addErrorMessage: "",
      editErrorMessage: "",
      promptForRemoval: false,
      query: "",
      autoCompleteLoading: false,
    };
  },
  methods: {
    openFinishableOrder: function (order: IOrder) {
      this.orderOverview.isOpen = true;
      this.orderOverview.finishable = true;
      this.orderOverview.order = order;
    },
    openArchivedOrder: function (order: IOrder) {
      this.orderOverview.isOpen = true;
      this.orderOverview.finishable = false;
      this.orderOverview.order = order;
    },
    closeOrder: function () {
      this.orderOverview.isOpen = false;
      this.orderOverview.finishable = false;
      this.orderOverview.order = {} as IOrder;
    },
    loadOrderPage: function (pageIndex: number, pageSize: number) {
      this.isLoading = true;
      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&query.finished=false";

      this.orderTab.orders.splice(0);

      return axios
        .get("/orders" + params)
        .then((response) => {

          this.orderTab.pageIndex = pageIndex;
          this.orderTab.totalSize = response.data.totalSize;

          let orders = response.data.orders;
          orders.forEach((order: IOrderDTO) => {
            let orderObj = {
              orderId: order.orderId,
              creationDate: DateTime.fromSQL(order.creationDate),
              deliveryDate: DateTime.fromSQL(order.deliveryDate),
              user: order.user,
              items: order.items,
            };

            this.orderTab.orders.push(orderObj);
          });

          this.isLoading = false;
        })
        .catch((reason) => {});
    },
    loadArchivePage: function (pageIndex: number, pageSize: number) {
      this.isLoading = true;

      this.archiveTab.orders.splice(0);

      let params = "?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&query.finished=true";
      return axios
        .get("/orders" + params)
        .then((response) => {

          this.archiveTab.pageIndex = pageIndex;
          this.archiveTab.totalSize = response.data.totalSize;

          let orders = response.data.orders;
          orders.forEach((order: IOrderDTO) => {
            let orderObj = {
              orderId: order.orderId,
              creationDate: DateTime.fromSQL(order.creationDate),
              deliveryDate: DateTime.fromSQL(order.deliveryDate),
              user: order.user,
              items: order.items,
            };

            this.archiveTab.orders.push(orderObj);
          });

          this.isLoading = false;
        })
        .catch((reason) => {});
    },
    finishOrder: function () {
      this.isFinishingOrder = true;
      let orderId = this.orderOverview.order.orderId;
      axios
        .put("/orders", { orderId: orderId, isFinished: true })
        .then((response) => {
          this.closeOrder();
          this.loadOrderPage(0, this.orderTab.pageSize);
        })
        .catch((reason) => {})
        .finally(() => {
          this.isFinishingOrder = false;
        });
    },
  },
  computed: {
    overviewOrderDate: function (): string {
      return this.formatDate(this.orderOverview.order.creationDate);
    },
    overviewDeliveryDate: function (): string {
      return this.formatDate(this.orderOverview.order.deliveryDate);
    }
  },
  watch: {
    activeTab: function(value: string) {
      this.orderTab.orders = [];
      this.archiveTab.orders = [];

      if (value == "orders")
        this.loadOrderPage(0, this.orderTab.pageSize);

      if (value == "archive")
        this.loadArchivePage(0, this.archiveTab.pageSize);
    }
  },
  mounted: function () {
    this.loadOrderPage(0, 25);
  },
});
</script>

<style lang="scss">
@import "../scss/global.scss";

h1 {
  margin-bottom: 25px;
}

.action-container {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}

</style>

<style scoped lang="scss">
@import "../scss/global.scss";

.finish-loading {
  background-color: $green;
  color: white !important;
}

.loading {
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.order-actions {
  display: flex;
}

.order-number {
  font-size: 1.2em;
  margin: 0;
  font-weight: 400;
}

.back {
  margin-right: 10px;
}

a.mail {
  color: $lighter-blue;
}

.orders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
}

.title-container .loader {
  margin-left: 15px;
  width: 24px;
  height: 24px;
}

.info {
  background-color: $blue;
  padding: 15px;
  margin: 10px 0px;
}

</style>
