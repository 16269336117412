<template>
    <div>
        <label>{{ label }}</label>
        <div class="checkbox-container" @click="toggle">
            <input type="checkbox" v-model="modelValue">
            <div class="thumb"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle: function () {
      let state = !this.modelValue;
      this.$emit("update:modelValue", state);
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.checkbox-container {
  position: relative;
  background-color: $light-blue;
  width: 16px;
  height: 16px;

  cursor: pointer;
}

.checkbox-container:hover .thumb {
  opacity: 0.5;
}

.thumb {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 10px;
  height: 10px;
  background-color: white;

  transform: translate(-50%, -50%);
}

input[type="checkbox"] {
  display: none;
}

input:checked ~ .thumb {
    opacity: 1 !important;
}

input ~ .thumb {
    opacity: 0;
}

</style>
