<template>

    <div class="table-container">
        <table>
            <thead>
                <slot name="columns"></slot>
            </thead>
            <tbody>
                <slot name="rows"></slot>
                <slot name="skeleton-rows"></slot>
            </tbody>
        </table>
    </div>

</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
    name: "TableView",
    setup() {
        
    },
});

</script>

<style lang="scss" scoped>

.table-container {
  flex-grow: 1;
  overflow-y: scroll;
  scrollbar-width: thin;
  position: relative;
}

table {
  border-collapse: separate;
  width: 100%;
  overflow-y: scroll;
  border-spacing: 0px 4px;
}

:deep(thead th) {
  font-size: 1.2em;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px 10px 10px;
  position: sticky;
  top: 0;
  background-color: #2B2B33;
  z-index: 1;
}

</style>
