
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "SideNavigation",
  props: {
    account: Object,
    msg: String,
  },
  methods: {
    logout: function () {
      axios
        .post("/auth/logout", {
          refreshToken: localStorage.getItem("refreshToken"),
        })
        .then((response) => {
          localStorage.clear();
          this.$router.push("/login");
        });
    },
    isActive: function(currRoute: string): boolean {
      return this.$route.path == currRoute;
    }
  }
});
