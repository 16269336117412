<template>
    <tr v-if="isVisible">
        <template v-for="n in columnNumber" :key="n">
            <td>
                <div class="content-loader"></div>
            </td>
        </template>
    </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SkeletonRow",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    columnNumber: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {};
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

tr {
  background-color: $blue;
  transition: background-color 0.2s;
}

tr td {
  height: 48px;
  padding-left: 10px;
}

tr td .content-loader {
    height: 24px;
    background-color: $light-blue;

    animation: pulse 3s infinite;
}

@keyframes pulse {
  0% { opacity: 1 }
  50% { opacity: 0.25 }
  100% { opacity: 1 }
}

</style>
