<template>
    <div class="parent">
        <div>
            <div class="input-container" :class="{ focus: hasFocus, error: hasError, interactable: !readonly }">
                <div class="frame">
                    <label :class="{ floating: shouldLabelFloat }" v-if="label !== undefined && label !== ''">{{label}}</label>
                    <div class="number-input">
                        <input type="text" :value="input" @input="inputChanged" :class="inputClasses" @focus="focus" @blur="unfocus" :readonly="readonly">
                    </div>
                </div>
            </div>
            <div class="actions" v-if="!readonly">
                <button class="increase" @click="increase">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                    </svg>
                </button>
                <button class="decrease" @click="decrease">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                </button>
            </div>
        </div>
        <p class="input-error" :class="{ visible: hasError }" v-if="enableError">{{ errorMsg }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NumberInput",
  props: {
    label: String,
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    step: {
      type: Number,
      default: 1
    },
    decimals: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: Number,
      required: true
    },
    inputClasses: Array,
    useComma: {
      type: Boolean,
      default: false
    },
    errorMsg: String,
    enableError: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      hasFocus: false,
    };
  },
  methods: {
    focus: function () {
      if (!this.readonly)
        this.hasFocus = true;
    },
    unfocus: function () {
      this.hasFocus = false;
    },
    increase: function () {
      if (this.modelValue! + this.step! <= this.max!) {
        this.$emit("update:modelValue", this.modelValue! + this.step!);
        this.$emit("update:errorMsg", "");
      }
    },
    decrease: function () {
      if (this.modelValue! - this.step! >= this.min!) {
        this.$emit("update:modelValue", this.modelValue! - this.step!);
        this.$emit("update:errorMsg", "");
      }
    },
    inputChanged: function (event: InputEvent) {
      let numberInput: string = (event.target as any).value;
      numberInput = numberInput.replace(",", ".");

      let parsedInput = parseFloat(numberInput);
      if (isNaN(parsedInput)) parsedInput = this.min ? this.min : 0;

      let roundedValue = parseFloat(parsedInput.toFixed(this.decimals));
      this.$emit("update:modelValue", roundedValue);
      this.$emit("update:errorMsg", "");
    },
  },
  computed: {
    input: function (): string {
      return this.useComma
        ? this.modelValue!.toString().replace(".", ",")
        : this.modelValue!.toString();
    },
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.input != "";
    },
    hasError: function (): boolean {
      return this.errorMsg !== undefined && this.errorMsg !== "";
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.parent > div {
  display: flex;
}

label {
  margin-bottom: 5px;
}

.number-input {
  display: flex;
}

.actions .increase,
.actions .decrease {
  color: $white;
  background-color: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 50%;
}

.actions .increase:hover,
.actions .decrease:hover {
  background-color: $light-blue;
}
</style>
