

import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    pageIndex: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    totalSize: {
      type: Number,
      required: true
    }
  },
  methods: {
    goNext() {
      this.$emit("load-page", this.pageIndex! + 1, this.pageSize);
    },
    goBack() {
      this.$emit("load-page", this.pageIndex! - 1, this.pageSize);
    },
  },
  computed: {
    canBack: function (): boolean {
      return this.pageIndex! > 0;
    },
    canNext: function (): boolean {
      return (this.pageIndex! + 1) * this.pageSize! < this.totalSize!;
    },
  },
});
