<template>
    <nav class="side">
        <router-link class="goto-profile" to="/account">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            <p>{{ account.name }}</p>
        </router-link>

        <ul class="nav-list">
            <li class="nav-item" :class="{ active: isActive('/users') }">
                <router-link to="/users">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                    </svg>
                </router-link>
                <div class="nav-label">
                  <span>Gebruikers</span>
                </div>
            </li>
            <li class="nav-item" :class="{ active: isActive('/products') }">
                <router-link to="/products">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box" viewBox="0 0 16 16">
                        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                    </svg>
                </router-link>
                <div class="nav-label">
                  <span>Producten</span>
                </div>
            </li>
            <li class="nav-item" :class="{ active: isActive('/orders') }">
                <router-link to="/orders">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                </router-link>
                <div class="nav-label">
                  <span>Bestellingen</span>
                </div>
            </li>
            <li class="nav-item" :class="{ active: isActive('/website') }">
                <router-link to="/website">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                    </svg>
                </router-link>
                <div class="nav-label">
                  <span>Website</span>
                </div>
            </li>
        </ul>

        <div class="bottom-container">
            <button class="logout" @click="logout">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg>
            </button>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "SideNavigation",
  props: {
    account: Object,
    msg: String,
  },
  methods: {
    logout: function () {
      axios
        .post("/auth/logout", {
          refreshToken: localStorage.getItem("refreshToken"),
        })
        .then((response) => {
          localStorage.clear();
          this.$router.push("/login");
        });
    },
    isActive: function(currRoute: string): boolean {
      return this.$route.path == currRoute;
    }
  }
});
</script>

<style scoped lang="scss">
@import "../scss/global.scss";

nav.side {
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

nav.side a {
  color: $white;
}

nav.side ul {
  list-style-type: none;
}

a.goto-profile {
  padding: 10px 0px 10px 0px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

a.goto-profile:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

a.goto-profile svg {
  display: block;
  width: 28px;
  height: 28px;
}

a.goto-profile p {
  font-size: 1.2em;
  margin: 5px 0px 0px 0px;
}

.bottom-container {
  display: flex;
  width: 100%;
}

.logout {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  padding: 0px;
  width: 100%;
  height: 40px;
  font-family: inherit;
  font-weight: 300;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
}

.logout:hover {
  background-color: $red;
}

.logout svg {
  width: 24px;
  height: 24px;
}

.nav-list {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 15px 0px 15px 0px;
}

.nav-item {
  transition: background-color 0.3s;
  box-sizing: border-box;
  margin-bottom: 5px;

  position: relative;

  cursor: pointer;
}

.nav-item a {
    padding: 10px 0px 10px 0px;
}

.nav-item,
.nav-item a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.nav-item a svg {
  width: 20px;
  height: 20px;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-item.active {
  border-right: 2px solid white;
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-item .nav-label {
  position: absolute;
  top: 0;
  left: 100%;
  opacity: 0;
  height: 100%;
  padding: 0px 15px;
  margin: 0;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
  width: 0px;

  display: flex;
  align-items: center;

  transition: width 0.3s;
  pointer-events: none;
}

.nav-item:hover .nav-label {
  opacity: 1;
  width: 100px;
}

.nav-item:hover .nav-label span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

button.info {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 5px 0px 5px 0px;
  background-color: rgba(0, 0, 0, 0);
  color: $white;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  font-weight: 300;
  width: 50%;
}

button.info:hover {
  background-color: $light-gray;
}

button.info svg {
  margin-right: 5px;
}
</style>
