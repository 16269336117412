<template>
    <div class="pagination">
        <button class="previous" :class="{ visible: canBack, invisible: !canBack }" @click="goBack">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
            </svg>
        </button>
        <div class="page-number">
            <p>{{ pageIndex + 1 }}</p>
        </div>
        <button class="next" :class="{ visible: canNext, invisible: !canNext }" @click="goNext">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
        </button>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    pageIndex: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    totalSize: {
      type: Number,
      required: true
    }
  },
  methods: {
    goNext() {
      this.$emit("load-page", this.pageIndex! + 1, this.pageSize);
    },
    goBack() {
      this.$emit("load-page", this.pageIndex! - 1, this.pageSize);
    },
  },
  computed: {
    canBack: function (): boolean {
      return this.pageIndex! > 0;
    },
    canNext: function (): boolean {
      return (this.pageIndex! + 1) * this.pageSize! < this.totalSize!;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

.pagination {
  margin-top: 10px;
  display: flex;
  height: 32px;
}

.visible {
  opacity: 1;
  pointer-events: auto;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.pagination .previous,
.pagination .next {
  width: 32px;
  background: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}

.pagination .previous:hover,
.pagination .next:hover {
  background-color: $light-blue;
}

.pagination .previous:hover svg,
.pagination .next:hover svg {
  fill: $white;
}

.pagination .page-number {
  border-top: 1px solid $blue;
  border-bottom: 1px solid $blue;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
}

.pagination .previous svg,
.pagination .next svg {
  fill: $blue;
  transition: fill 0.3s;
}
</style>
