

import { defineComponent } from 'vue'

export default defineComponent({
    name: "TableView",
    setup() {
        
    },
});

