<template>
    <div class="overlay" v-bind:class="isOpen ? 'open' : 'closed'">

        <div class="dialog warning" :class="properties.type">
            <div class="top-bar">
                <button class="close" v-on:click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                    </svg>
                </button>
            </div>

            <div class="content">
                <h2>Waarschuwing</h2>
                <p>De actie die u gaat uitvoeren kan niet ongedaan gemaakt worden.</p>
                <p>Bent u zeker dat u wil doorgaan?</p>
                <dialog-actions class="buttons" :submitText="'Doorgaan'" :cancelText="''" :isLoading="isLoading" :errorMessage="errorMessage" @submit="submit" @cancel="close"></dialog-actions>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DialogActions from "./DialogActions.vue";
import { IDialogProperties } from "../../models/DialogProperties";

export default defineComponent({
  name: "WarningDialog",
  components: {
    DialogActions,
  },
  props: {
    properties: {
      type: Object as () => IDialogProperties,
      default: function () {
        return {
          type: "add",
          submitText: "Toevoegen",
          cancelText: "Verwerpen",
        };
      },
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    errorMessage: String,
  },
  data: function () {
    return {
      isLoading: false,
    };
  },
  methods: {
    submit: function () {
      this.isLoading = true;
      this.$emit("submit");
    },
    open: function() {
        this.$emit("update:isOpen", true);
    },
    close: function () {
      this.$emit("update:isOpen", false);
    },
  },
  watch: {
    isOpen: function (value) {
        if (!value)
            this.isLoading = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.dialog.warning {
  border-color: $red;
}

.dialog.warning h2 {
  margin-bottom: 25px;
}

.dialog.warning p {
  margin: 0px;
}

.dialog.warning .buttons {
  margin-top: 15px;
}

:deep(.dialog.warning .submit) {
  border-color: $red;
  color: $red;
}

:deep(.dialog.warning .submit:hover) {
  background-color: $red;
  color: $white;
}

:deep(.dialog.warning .loading) {
  background-color: $red;
}

:deep(.cancel) {
  border-color: $red;
  color: $red;
}

:deep(.cancel:hover) {
  background-color: $red;
  color: $white;
}
</style>
