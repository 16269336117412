
import { defineComponent } from "vue";
import DialogActions from "./DialogActions.vue";
import { IDialogProperties } from "../../models/DialogProperties";

export default defineComponent({
  name: "WarningDialog",
  components: {
    DialogActions,
  },
  props: {
    properties: {
      type: Object as () => IDialogProperties,
      default: function () {
        return {
          type: "add",
          submitText: "Toevoegen",
          cancelText: "Verwerpen",
        };
      },
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    errorMessage: String,
  },
  data: function () {
    return {
      isLoading: false,
    };
  },
  methods: {
    submit: function () {
      this.isLoading = true;
      this.$emit("submit");
    },
    open: function() {
        this.$emit("update:isOpen", true);
    },
    close: function () {
      this.$emit("update:isOpen", false);
    },
  },
  watch: {
    isOpen: function (value) {
        if (!value)
            this.isLoading = false;
    },
  },
});
