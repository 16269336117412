<template>
    <div class="account">
        <h1>Account</h1>
        <div v-show="!isLoading">
            <text-input class="name-input" v-if="!isLoading" ref="nameInput" :label="'Naam'" v-model="user.name" v-model:isEditing="nameInput.isEditing" :toggleable="true" @modified="setNameChanged"></text-input>
            <text-input class="email-input" v-if="!isLoading" ref="emailInput" :label="'E-mail'" v-model="user.email" v-model:isEditing="emailInput.isEditing" :toggleable="true" @modified="setEmailChanged"></text-input>

            <password-input v-if="!isLoading" ref="passwordInput" :label="'Wachtwoord'" v-model="password"></password-input>

            <div class="roles-container" v-if="!isLoading">
                <p>Rollen</p>
                <ul class="roles">
                    <li class="tag" v-for="role in user.roles" :key="role.id">{{ role.label }}</li>
                </ul>
            </div>

            <div v-if="hasChanges && !isLoading">
                <button class="submit" @click="openPasswordDialog">Toepassen</button>
                <button class="cancel" @click="cancelAllChanges">Verwerpen</button>
            </div>

            <div class="overlay" v-if="isWaitingForPassword && !isLoading">
                <div class="dialog confirmation">
                    <p class="notice">Vul je huidig wachtwoord in om de verandering door te voeren.</p>
                    <password-input :label="'Wachtwoord'" :toggleable="false" v-model="currentPassword"></password-input>
                    <button class="submit" @click="sendChanges">
                        Verzenden
                    </button>
                    <button class="cancel" @click="cancelAllChanges(); closePasswordDialog()">
                        Verwerpen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import { IUser } from "../models/User";
import axios from "axios";

export default defineComponent({
  name: "Account",
  components: {
    TextInput,
    PasswordInput,
  },
  data: function () {
    return {
      user: {} as IUser,
      nameInput: {
        isChanged: false,
        isEditing: false,
      },
      emailInput: {
        isChanged: false,
        isEditing: false,
      },
      password: "",
      currentPassword: "",
      isWaitingForPassword: false,
      isLoading: false,
    };
  },
  methods: {
    setNameChanged: function (value: boolean) {
      this.nameInput.isChanged = value;
    },
    setEmailChanged: function (value: boolean) {
      this.emailInput.isChanged = value;
    },
    openPasswordDialog: function () {
      this.isWaitingForPassword = true;
    },
    sendChanges: function () {
      let changes: any = {};
      if (this.nameInput.isChanged) changes["name"] = this.user.name;

      if (this.emailInput.isChanged) changes["email"] = this.user.email;

      if (this.password != "") changes["password"] = this.password;

      axios
        .put("/account", { password: this.currentPassword, change: changes })
        .then((response) => {
          this.closePasswordDialog();
          if (response.status === 400 || response.status === 500)
            this.cancelAllChanges();
          else {
            this.nameInput.isChanged = false;
            this.nameInput.isEditing = false;

            this.emailInput.isChanged = false;
            this.emailInput.isEditing = false;
          }
        });
    },
    cancelAllChanges: function () {
      (this.$refs.nameInput as any).cancelEdit();
      (this.$refs.emailInput as any).cancelEdit();
      (this.$refs.passwordInput as any).cancelEdit();
    },
    closePasswordDialog: function () {
      this.isWaitingForPassword = false;
    },
  },
  mounted() {
    this.isLoading = true;
    this.$emit("notification", "Aan het laden", "");
    axios.get("/account").then((response) => {
      this.user = response.data.user;
      this.isLoading = false;
      this.$emit("notification", "", "");
    });
  },
  computed: {
    hasChanges: function (): boolean {
      return (
        this.password !== "" ||
        this.nameInput.isChanged ||
        this.emailInput.isChanged
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

.account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name-input {
  margin-bottom: 20px;
}

.email-input {
  margin-bottom: 20px;
}

.dialog.confirmation {
  border-color: $blue;
}

.confirmation .notice {
  margin: 0px 0px 25px 0px;
}

.submit {
  background-color: $green;
  padding: 10px;
  color: $white;

  margin-right: 5px;
}

.cancel {
  background-color: $red;
  padding: 10px;
  color: $white;
}

h1 {
  margin-bottom: 15px;
}

.roles-container {
  margin-bottom: 15px;
}

.roles-container > p {
  margin: 0px 0px 10px 0px;
}

ul.roles {
  display: flex;
}
</style>
