<template>
    <div class="parent">
        <div>
            <div class="input-container" :class="{ focus: hasFocus, error: hasError, interactable: !toggleable || isEditing }">
                <div class="frame">
                    <label :class="{ floating: shouldLabelFloat }">{{ label }}</label>
                    <input type="text" ref="input" v-bind:class="{ editing: isEditing }" :value="modelValue" @input="change" :classes="inputClasses" :disabled="toggleable && !isEditing" @focus="focus" @blur="unfocus">
                </div>
            </div>
            <div class="actions" v-if="toggleable">
                <button class="edit" @click="startEditing" v-if="!isEditing">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                </button>
                <div class="modify-buttons" v-if="isEditing">
                    <button class="cancel" @click="cancelEdit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <p class="input-error" :class="{ visible: hasError }" v-if="enableError">{{ errorMsg }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextInput",
  props: {
    label: String,
    modelValue: {
      type: String,
      required: true
    },
    enableError: {
      type: Boolean,
      default: true
    },
    errorMsg: String,
    toggleable: {
      type: Boolean,
      default: false
    },
    inputClasses: Array,
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      originalInput: "",
      errors: [] as string[],
      hasFocus: false,
    };
  },
  methods: {
    focus: function () {
      this.hasFocus = true;
    },
    unfocus: function () {
      this.hasFocus = false;
    },
    cancelEdit: function () {
      this.$emit("update:isEditing", false);
      this.$emit("update:modelValue", this.originalInput);

      this.$emit("modified", false);
    },
    startEditing: function () {
      this.$emit("update:isEditing", true);
      this.originalInput = this.modelValue!;

      (this.$refs.input as any).focus();
    },
    submitEdit: function () {
      this.$emit("update:isEditing", false);
      this.originalInput = "";
    },
    change: function (event: Event) {
      if ((event.target as any).value !== this.originalInput)
        this.$emit("modified", true);

      this.$emit("update:modelValue", (event.target as any).value);
      this.$emit("update:errorMsg", "");
    },
  },
  computed: {
    hasError: function (): boolean {
      return this.errorMsg !== undefined && this.errorMsg !== "";
    },
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.modelValue != "";
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.parent > div {
  display: flex;
}

.actions {
  display: flex;
  margin-left: 5px;
}

.modify-buttons {
  display: flex;
  flex-direction: column;
}

.input-container > p {
  margin: 0;
}

p.error {
  margin: 0px;
  color: $red;
  font-size: 0.8em;
}

button {
  margin: 0;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
}

button.edit {
  width: 32px;
  background-color: $orange;
}

button.edit:hover {
  background-color: $light-orange;
}

.modify-buttons button.cancel {
  background-color: $red;
  width: 32px;
}

.modify-buttons button.cancel:hover {
  background-color: $light-red;
}
</style>
