<template>
    <div class="section">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "SectionView",
    setup() {
        
    },
})
</script>

<style lang="scss" scoped>
@import "../scss/global.scss";

.section {
    background-color: $blue;
    border-left: 2px solid white;
    padding: 25px;
    box-sizing: border-box;
}

</style>
