<template>
    <div class="developer">
        <h1>Ontwikkelaar</h1>
            <tab-view :labels="tabLabels" v-model:activeTab="activeTab">
            <template v-slot:logs>
                <p>Logging period: <span>01-09</span> >> <span>14-09</span></p>
                <table-view>
                    <template v-slot:columns>
                        <column :width="'50%'">Module</column>
                        <column></column>
                    </template>
                    <template v-slot:rows>
                        <row v-for="row in logsTab.logRows" :key="row.log.module" :model="row.log" :viewableKeys="['module']" :visible="row.visible">
                            <template v-slot:actions>
                                <button class="download-button green">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                        <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                    </svg>
                                </button>
                            </template>
                        </row>
                    </template>
                </table-view>
            </template>
            <template v-slot:email-settings>
                <section-view class="section">
                    <h2>Foutmeldingen</h2>
                    <text-input :label="'E-mail'" v-model="emailSettingsTab.reporting.email" v-model:isEditing="emailSettingsTab.reporting.isEditing" :toggleable="true"></text-input>
                </section-view> 
                <section-view class="section">
                    <h2>Systeem</h2>
                    <text-input :label="'Host'" v-model="emailSettingsTab.sending.hostInput.value" v-model:isEditing="emailSettingsTab.sending.hostInput.isEditing" :toggleable="true"></text-input>
                    <text-input :label="'E-mail'" v-model="emailSettingsTab.sending.emailInput.value" v-model:isEditing="emailSettingsTab.sending.emailInput.isEditing" :toggleable="true"></text-input>
                    <password-input :label="'Wachtwoord'" v-model="emailSettingsTab.sending.passwordInput.value" v-model:isEditing="emailSettingsTab.sending.hostInput.isEditing" :toggleable="true"></password-input>
                </section-view>
                <section-view class="section">
                    <h2>Orders ontvangen</h2>
                    <text-input :label="'E-mail'" v-model="emailSettingsTab.order.emailInput[0].value" v-model:isEditing="emailSettingsTab.order.emailInput[0].isEditing" :toggleable="true"></text-input>
                    <text-input :label="'E-mail'" v-model="emailSettingsTab.order.emailInput[1].value" v-model:isEditing="emailSettingsTab.order.emailInput[1].isEditing" :toggleable="true"></text-input>
                    <text-input :label="'E-mail'" v-model="emailSettingsTab.order.emailInput[2].value" v-model:isEditing="emailSettingsTab.order.emailInput[2].isEditing" :toggleable="true"></text-input>
                </section-view>
            </template>
            </tab-view>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import Row from "../components/table/Row.vue";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import { DateFormatting } from "../mixins/DateFormatting";
import TableView from "../components/table/TableView.vue";
import SkeletonRow from "../components/table/SkeletonRow.vue";
import SectionView from "../components/SectionView.vue";
import TabView from "../components/tab-view/TabView.vue";
import Tab from "../components/tab-view/Tab.vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";

export default defineComponent({
  name: "Developer",
  mixins: [DateFormatting],
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    WarningDialog,
    TableView,
    SkeletonRow,
    SectionView,
    TabView,
    TextInput,
    PasswordInput
  },
  data: function () {
    return {
      isLoading: false,
      tabLabels: [
        { tab: "logs", label: "Logs" },
        { tab: "email-settings", label: "E-mail instellingen" },
      ],
      activeTab: "logs",
      logsTab: {
        logRows: [
            {
                isVisible: true,
                log: {
                    module: "auth"
                }
            },
            {
                isVisible: true,
                log: {
                    module: "auth"
                }
            },
            {
                isVisible: true,
                log: {
                    module: "auth"
                }
            }
        ],
      },
      emailSettingsTab: {
          reporting: {
              email: "",
              isEditing: false
          },
          sending: {
              hostInput: {
                  value: "",
                  isEditing: false
              },
              emailInput: {
                  value: "",
                  isEditing: false
              },
              passwordInput: {
                  value: "",
                  isEditing: false
              }
          },
          order: {
              emailInput: [
                  {
                      value: "",
                      isEditing: false
                  },
                  {
                      value: "",
                      isEditing: false
                  },
                  {
                      value: "",
                      isEditing: false
                  }
              ]
          }
      }
    };
  },
  methods: {
  },
  computed: {
  },
  watch: {
    activeTab: function(value: string) {
    }
  },
  mounted: function () {
  },
});
</script>

<style lang="scss">
@import "../scss/global.scss";

h1 {
  margin-bottom: 25px;
}

.developer {
    height: 100%; 
    display: flex;
    flex-direction: column;
}

.action-container {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}

</style>

<style scoped lang="scss">
@import "../scss/global.scss";

.section {
    margin-bottom: 25px;
    max-width: 512px;
}

.download-button {
    width: 32px;
    height: 32px;
    background-color: $green;

    transition: background-color 0.2s;
}

.download-button:hover {
    background-color: $light-green;
}


</style>
