
import { defineComponent } from 'vue';
import { IOrder} from "../../models/IOrder";
import Row from "../table/Row.vue";
import Column from "../table/Column.vue";
import { DateFormatting } from "../../mixins/DateFormatting";
import TableView from "../table/TableView.vue";
import SkeletonRow from '../table/SkeletonRow.vue';

interface IOrderRow {
  visible: boolean;
  order: IOrder;
}

export default defineComponent({
    setup() {
        
    },
    mixins: [DateFormatting],
    components: {
        Row,
        Column,
        TableView,
        SkeletonRow
    },
    props: {
        modelValue: {
            type: Array as () => IOrder[],
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            rows: [] as IOrderRow[]
        }
    },
    methods: {
        openOrder: function(order: IOrder) {
            this.$emit("openOrder", order);
        }
    },
    watch: {
        modelValue: {
            handler() {
                this.rows = [];

                this.modelValue?.forEach((value: IOrder) => {
                    this.rows.push({ visible: true, order: value });
                });
            },
            deep: true
        }
    }
});

