
import GlobalMessage from "../components/GlobalMessage.vue";
import SideNavigation from "../components/SideNavigation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SidebarLayout",
  components: {
    GlobalMessage,
    SideNavigation,
  },
  props: {
    account: Object
  },
  data: function () {
    return {
      message: "",
      messageClass: "",
      showLoadingIcon: true
    };
  },
  methods: {
    showMessage(message: string, messageClass: string, showLoadingIcon?: boolean) {
      this.message = message;
      this.messageClass = messageClass;
      this.showLoadingIcon = showLoadingIcon ? showLoadingIcon : true;
    },
    hideMessage() {
      this.message = "";
      this.messageClass = "";
    },
  },
});
