<template>
        <table-view>
            <template v-slot:columns>
                    <column :width="'10%'">Order ID</column>
                    <column :width="'45%'">Orderdatum</column>
                    <column :width="'45%'">Afhaaldatum</column>
                    <column></column>
            </template>
            <template v-slot:rows>
                <row v-for="row in rows" :key="row.order.id" :model="row.order" :viewableKeys="['orderId', 'creationDate', 'deliveryDate']" :visible="row.visible" @edit="openEditDialog" @remove="requestRemove">
                    <template v-slot:creationDate>
                        <span>{{ formatDate(row.order.creationDate) }}</span>
                    </template>
                    <template v-slot:deliveryDate>
                        <span>{{ formatDate(row.order.deliveryDate) }}</span>
                    </template>
                    <template v-slot:actions>
                        <button class="open-order" @click="openOrder(row.order)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-clipboard" viewBox="0 0 16 16">
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                            </svg>
                        </button>
                    </template>
                </row>
            </template>

            <template v-slot:skeleton-rows>
                <skeleton-row v-for="n in 25" :key="n" :columnNumber="4" :isVisible="isLoading"></skeleton-row>
            </template>
        </table-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IOrder} from "../../models/IOrder";
import Row from "../table/Row.vue";
import Column from "../table/Column.vue";
import { DateFormatting } from "../../mixins/DateFormatting";
import TableView from "../table/TableView.vue";
import SkeletonRow from '../table/SkeletonRow.vue';

interface IOrderRow {
  visible: boolean;
  order: IOrder;
}

export default defineComponent({
    setup() {
        
    },
    mixins: [DateFormatting],
    components: {
        Row,
        Column,
        TableView,
        SkeletonRow
    },
    props: {
        modelValue: {
            type: Array as () => IOrder[],
            required: true
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            rows: [] as IOrderRow[]
        }
    },
    methods: {
        openOrder: function(order: IOrder) {
            this.$emit("openOrder", order);
        }
    },
    watch: {
        modelValue: {
            handler() {
                this.rows = [];

                this.modelValue?.forEach((value: IOrder) => {
                    this.rows.push({ visible: true, order: value });
                });
            },
            deep: true
        }
    }
});

</script>

<style lang="scss" scoped>

@import "../../scss/global.scss";

.open-order {
  background-color: $lighter-blue;
  width: 32px;
  height: 32px;

  transition: background-color 0.3s;
}

.open-order:hover {
  background-color: $dark-blue;
}

</style>