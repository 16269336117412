import { DateTime } from "luxon";

export var DateFormatting = {
    methods: {
        formatDate: function (date: DateTime): string {
            let year = date.year.toString();
            let month = (date.month + 1).toString();
            if (month.length == 1)
                month = "0" + month;

            let day = date.day.toString();
            if (day.length == 1)
                day = "0" + day;

            let hour = date.hour.toString();
            if (hour.length == 1)
                hour = "0" + hour;

            let minute = date.minute.toString();
            if (minute.length == 1)
                minute = "0" + minute;

            return day + "/" + month + "/" + year + " " + hour + ":" + minute;
        }
    }
}