import { createApp } from 'vue'
import App from './App.vue'
import Vue from "vue";
import router from './router'
import { makeServer } from "./mock/server";
import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Vuelidate from '@vuelidate/core'
import { ICustomAxiosRequestConfig } from './models/ICustomAxiosRequestConfig';

// if (process.env.NODE_ENV === "development") {
//     makeServer();
// }

//axios.defaults.baseURL = "https://api.grfrpauwels.local";

if (process.env.NODE_ENV === "development")
    axios.defaults.baseURL = "https://api.grfrpauwels.local";
else
    axios.defaults.baseURL = "https://api.groentenenfruitpauwels.be";

//axios.defaults.withCredentials = true;

const refreshAuthLogic = (request: any) => {
    let refreshToken = window.localStorage.getItem("refreshToken");
    return axios.post("/auth/refresh", { refreshToken: refreshToken }, { withCredentials: true }).then(response => {

        window.localStorage.setItem("accessToken", response.data.accessToken);
        window.localStorage.setItem("refreshToken", response.data.refreshToken);

        request.response.config.headers["Authorization"] = "Bearer " + response.data.refreshToken;
        return Promise.resolve();
    })
    .catch(reason => {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("refreshToken");

        router.push("/login");
        return Promise.reject();
    });
}

createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    let useAuthSetting = (config as ICustomAxiosRequestConfig).customSettings !== undefined ? (config as ICustomAxiosRequestConfig).customSettings.useAuth : true;
    let useAuth = config.url !== "/auth/login" && useAuthSetting;

    if (config.url !== "/auth/login" && useAuth) {
        let accessToken = window.localStorage.getItem("accessToken");
        config.headers["Authorization"] = "Bearer " + accessToken;
    }

    return config;
});

const app = createApp(App).use(router);

app.mount('#app');
