
import { defineComponent, getCurrentInstance } from "vue";
import Vue from "vue";
import TextInput from "../components/input/TextInput.vue";
import PasswordInput from "../components/input/PasswordInput.vue";
import axios from "axios";
import { IUser } from "../models/User";

export default defineComponent({
  name: "Login",
  components: {
    TextInput,
    PasswordInput,
  },
  data: function () {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    login: function () {
      if (!this.isLoading) {
        this.isLoading = true;
        axios
          .post("/auth/login", {
            email: this.email,
            password: this.password,
          }, { withCredentials: true })
          .then((response) => {

            window.localStorage.setItem(
              "accessToken",
              response.data.accessToken
            );
            window.localStorage.setItem(
              "refreshToken",
              response.data.refreshToken
            );

            return axios.get("/account");
          })
          .then((response) => {
            let user: IUser = response.data.user as IUser;
            if (user.roles.find((item) => item.label == "admin") !== undefined)
              this.$router.push("/users");
            else this.errorMessage = "Toegang verboden";
          })
          .catch((reason) => {
            console.log(reason.response.data);

            if (reason.response.data.invalidCredentials) {
              this.errorMessage = "Ongeldige gebruikersnaam of wachtwoord.";
              return;
            }

            if (reason.response.data.accessForbidden) {
              this.errorMessage = "Toegang verboden.";
              return;
            }

            if (reason.response.data.lockedOut) {
              this.errorMessage =
                "Uw account is tijdelijk geblokkeerd, probeer binnenkort opnieuw";
              return;
            }

            this.errorMessage = "Er is een onbekende fout opgetreden.";
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    hasError: function (): boolean {
      return (
        this.errorMessage !== "" &&
        this.errorMessage !== null &&
        this.errorMessage !== undefined
      );
    },
  },
  created: function() {
    let refreshToken = window.localStorage.getItem("refreshToken");
    if (refreshToken != null) {
      axios.post("/auth/refresh", { refreshToken: refreshToken })
      .then(response => {
        window.localStorage.setItem("accessToken", response.data.accessToken);
        window.localStorage.setItem("refreshToken", response.data.refreshToken);
        this.$router.push("/users");
      })
      .catch(reason => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      });
    }
  }
});
