
import { defineComponent } from "vue";
import TextInput from "../input/TextInput.vue";
import NumberInput from "../input/NumberInput.vue";
import Dropdown from "../input/Dropdown.vue";
import Slider from "../input/Slider.vue";
import ImageInput from "../input/ImageInput.vue";
import { IProduct } from "../../models/Product";
import DialogActions from "../dialog/DialogActions.vue";
import { IDialogProperties } from "@/models/DialogProperties";
import { ICategory } from "../../models/Category";
import axios from "axios";
import { ICustomAxiosRequestConfig } from "../../models/ICustomAxiosRequestConfig";

import {
  required,
  email,
  between,
  or,
  sameAs,
  url,
  minValue,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { IDropdownItem } from "../../models/DropdownItem";
import IImageInputData from "@/models/input/IImageInputData";
import IImageInputSettings from "@/models/input/IImageInputSettings";

export default defineComponent({
  name: "ProductDialog",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    TextInput,
    NumberInput,
    Dropdown,
    ImageInput,
    DialogActions,
    Slider
  },
  props: {
    properties: Object as () => IDialogProperties,
    modelValue: {
      type: Object as () => IProduct,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    categories: {
      type: Array as () => ICategory[],
      default: []
    },
    settings: Object,
    inputErrors: Object,
    isLoading: Boolean,
  },
  data: function () {
    return {
      imgInput: {
        placeholder: axios.defaults.baseURL + "/img/product/placeholder.jpg",
        data: {
          img: "",
          isChanged: false,
        } as IImageInputData,
        settings: {
          width: 320,
          height: 320,
          stencilWidth: 300,
          stencilHeight: 300,
          aspectRatio: 1 / 1,
          resultWidth: 280,
          resultHeight: 280,
        } as IImageInputSettings,
      },
      errorMessage: "",
      internalProduct: {
        id: 0,
        label: "",
        price: 0,
        priceUnit: "kg",
        image: "",
        isAvailable: false,
        categoryId: "0",
      },
    };
  },
  validations() {
    return {
      internalProduct: {
        id: { required, minValue: minValue(1) },
        label: { required },
        price: { required, between: between(0, 999999) },
        priceUnit: { required },
        categoryId: { required },
      },
    };
  },
  methods: {
    open: function () {
      this.$emit("update:isOpen", true);
    },

    close: function () {
      this.$emit("update:isOpen", false);
    },

    submit: function () {
      this.v$.$touch();

      if (!this.v$.$error) {
        this.$emit("update:isLoading", true);
        let imgData = this.imgInput.data.isChanged
          ? this.imgInput.data.img
          : null;

        this.$emit("submit", this.internalProduct, imgData);
      } else {
        if ((this.v$.internalProduct as any).label.required.$invalid)
          this.$emit("update:inputErrors", {
            ...this.inputErrors,
            label: "Label leeg",
          });
      }
    },

    loadImage: function (imageBlob: string) {
      this.internalProduct.image = imageBlob;
    },
  },
  computed: {
    categorySelectionList: function (): IDropdownItem[] {
      let arr = new Array<IDropdownItem>();
      this.categories!.forEach((category: ICategory) => {
        category.subCategories.forEach((sub) => {
          arr.push({ id: sub.id.toString(), label: sub.label });
        });
      });

      return arr;
    },
  },
  watch: {
    modelValue: function () {
      this.internalProduct = JSON.parse(JSON.stringify(this.modelValue!));
      let url = this.settings!.imgPath + this.internalProduct.image;
      axios
        .get(url, {
          responseType: "arraybuffer",
          customSettings: { useAuth: false },
        } as ICustomAxiosRequestConfig)
        .then((response) => {
          var arrayBufferView = new Uint8Array(response.data);
          var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(blob);
          this.imgInput.data.img = imageUrl;
        });
    },
    isOpen: function () {
      if (!this.isOpen) this.$emit("update:isLoading", false);
    },
    inputErrors: function () {
    },
  },
});
