<template>
    <div>
        <div class="input-container" :class="{ focus: hasFocus, interactable: !toggleable || isEditing }">
            <div class="frame">
                <label :class="{ floating: shouldLabelFloat }">{{label}}</label>
                <input :type="type" v-bind:class="hasError() ? 'error' : ''" :value="input" @input="change" @focus="focus" @blur="unfocus">
                <button class="toggle-visibility" @mousedown="reveal" @mouseup="hide" @mouseout="hide">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-if="!isRevealing">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16" v-if="isRevealing">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                </button>
            </div>
        </div>
        <button class="cancel" v-if="toggleable && isEditing" @click="cancelEdit">Verwerpen</button>
        <p class="error" v-if="errorMsg !== undefined || errorMsg !== ''">{{errorMsg}}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PasswordInput",
  props: {
    label: String,
    modelValue: {
      type: String,
      required: true
    },
    errorMsg: String,
    toggleable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      input: this.modelValue,
      errors: [] as string[],
      isEditing: false,
      isRevealing: false,
      type: "password",
      hasFocus: false,
    };
  },
  methods: {
    startEditing: function () {
      this.isEditing = true;
    },
    cancelEdit: function () {
      this.isEditing = false;
      this.$emit("update:modelValue", "");
    },
    reveal: function () {
      this.isRevealing = true;
      this.type = "text";
    },
    hide: function () {
      this.isRevealing = false;
      this.type = "password";
    },
    hasError: function () {
      return this.errorMsg !== undefined && this.errorMsg !== "";
    },
    change: function (event: Event) {
      this.$emit("update:modelValue", (event.target as any).value);
    },
    focus: function () {
      this.hasFocus = true;
    },
    unfocus: function () {
      this.hasFocus = false;
    },
  },
  computed: {
    shouldLabelFloat: function (): boolean {
      return this.hasFocus || this.modelValue != "";
    },
  },
  watch: {
    modelValue: function () {
      this.input = this.modelValue;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../scss/global.scss";

.toggle-visibility {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  color: $white;
  transform: translateY(-50%);
}

.toggle-visibility svg {
  display: block;
}

p.error {
  margin: 0px;
  color: $red;
  font-size: 0.8em;
}

input[type="password"].error {
  border-color: $red;
}

.password-reset {
  color: $white;
  background-color: $orange;
  height: 32px;
  padding: 0px 15px;
}

.password-reset:hover {
  background-color: $light-orange;
}

.cancel {
  background-color: $red;
  height: 32px;
  padding: 0px 15px;
  color: $white;
}
</style>
