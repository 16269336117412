<template>
    <div class="website">
        <h1>Website</h1>
        <div v-show="!isPageLoading">
            <section>
                <h2>Website onderhoud</h2>
                <checkbox v-model="maintenanceInput.value"></checkbox>
            </section>
            <section>
                <h2>Algemene boodschap</h2>
                <text-input :toggleable="true" :label="'Boodschap'" v-model="messageInput.message" v-model:isEditing="messageInput.isEditing" @modified="setMessageChanged"></text-input>
                <button class="upload-button" v-if="messageInput.isChanged" @click="updateMessage">Aanpassen</button>
            </section>
            <section>
                <h2>Hoofdfoto</h2>
                <image-input :placeholder="headerImageInput.placeholder" v-model="headerImageInput.inputData" :settings="headerImageInput.settings"></image-input>
                <button class="upload-button" v-if="headerImageInput.inputData.isChanged" @click="uploadHeaderImage">Uploaden</button>
            </section>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import { IProduct } from "../models/Product";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import SearchField from "../components/SearchField.vue";
import Column from "../components/table/Column.vue";
import Row from "../components/table/Row.vue";
import ProductDialog from "../components/products/ProductDialog.vue";
import WarningDialog from "../components/dialog/WarningDialog.vue";
import Checkbox from "../components/input/Slider.vue";
import TextInput from "../components/input/TextInput.vue";
import ImageInput from "@/components/input/ImageInput.vue";
import { ICustomAxiosRequestConfig } from "@/models/ICustomAxiosRequestConfig";

interface IProductRow {
  visible: boolean;
  isDeleting: boolean;
  product: IProduct;
}

export default defineComponent({
  name: "Website",
  components: {
    Row,
    Pagination,
    SearchField,
    Column,
    ProductDialog,
    WarningDialog,
    Checkbox,
    TextInput,
    ImageInput,
  },
  data: function () {
    return {
      maintenanceInput: {
        value: false,
      },
      headerImageInput: {
        placeholder: axios.defaults.baseURL + "/img/header-placeholder.jpg",
        inputData: {
          img: "",
          isChanged: false
        },
        settings: {
          width: 660,
          height: 260,
          stencilWidth: 640,
          stencilHeight: 240,
          aspectRatio: 8 / 3,
          resultWidth: 1920,
          resultHeight: 720,
        },
      },
      messageInput: {
        message: "",
        isChanged: false,
        isEditing: false,
      },
      isPageLoading: false,
    };
  },
  methods: {
    setMessageChanged: function (value: boolean) {
      this.messageInput.isChanged = value;
    },
    uploadHeaderImage: function () {
      if (
        this.headerImageInput.inputData.img &&
        this.headerImageInput.inputData.img !== ""
      ) {
        console.log("uploadin " + this.headerImageInput.inputData.img);
        return axios
          .get(this.headerImageInput.inputData.img, {
            baseURL: "",
            responseType: "blob",
          })
          .then((response) => {
            console.log("BLOG CONVERSION " + response.data);
            let imgData = new FormData();
            imgData.append("image", response.data, "image");

            return axios
              .post("/website/header-image", imgData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response) => {
                return response.data.image;
              });
          });
      } else throw new Error();
    },
    updateMessage: function () {
      axios
        .put("/website/message", { message: this.messageInput.message })
        .then((response) => {
          this.messageInput.isEditing = false;
          this.messageInput.isChanged = false;
        });
    },
    changeMaintenance: function (newValue: boolean) {
      axios
        .put("/website/maintenance", { isMaintenance: newValue })
        .then((response) => {});
    },
  },
  mounted: function () {
    this.isPageLoading = true;
    this.$emit("notification", "Aan het laden");
    axios
      .get("/website/message")
      .then((response) => {
        this.messageInput.message = response.data.message;
        return axios.get("/website/maintenance");
      })
      .then((response) => {
          this.maintenanceInput.value = response.data.maintenance;
          this.$watch("maintenanceInput.value", this.changeMaintenance);
          return axios.get("/img/header.jpg",
          {
            responseType: "arraybuffer",
            customSettings: { useAuth: false },
          } as ICustomAxiosRequestConfig)
      })
      .then((response) => {

          console.log("Received image");
          var arrayBufferView = new Uint8Array(response.data);
          var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(blob);
          this.headerImageInput.inputData.img = imageUrl;

          this.isPageLoading = false;
          this.$emit("notification", "");
      })
      .catch(() => {
        this.$emit("notification", "Fout");
      });
  },
});
</script>

<style scoped lang="scss">
@import "../scss/global.scss";

h1 {
  margin-bottom: 25px;
}

.products {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
}

.title-container .loader {
  margin-left: 15px;
  width: 24px;
  height: 24px;
}

.table-container {
  flex-grow: 1;
  overflow-y: scroll;
  border-bottom: 2px solid $blue;
  scrollbar-width: thin;
}

table {
  border-collapse: collapse;
  width: 100%;
  overflow-y: scroll;
}

thead th {
  font-size: 1.2em;
  font-weight: 400;
  text-align: left;
  padding: 10px 0px 10px 10px;
  position: sticky;
  top: 0;
  background-color: $gray;
  z-index: 5;
}

.add-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0px;
  color: $white;
  background-color: $green;
}

.add-product:hover {
  background-color: $light-green;
}

.add-product svg {
  display: block;
  width: 24px;
  height: 24px;
}

h2 {
  margin-bottom: 10px;
}

section {
  margin: 25px 0px;
}

.upload-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0px 10px;
  color: $white;
  background-color: $green;
  margin-top: 10px;
}

.upload-button:hover {
  background-color: $light-green;
}
</style>
