
import { defineComponent } from 'vue'

export default defineComponent({
    name: "RowActions",
    props: {
      settings: {
        type: Object,
        default: {
          hasRemove: true,
          hasEdit: true
        }
      }
    },
    methods: {
        edit: function() {
            this.$emit("edit");
        },
        remove: function() {
            this.$emit("remove");
        }
    }
})
