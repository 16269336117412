
import { defineComponent } from "vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import SidebarLayout from "./layouts/SidebarLayout.vue";
import { IUser } from "./models/User";

export default defineComponent({
  name: "App",
  components: {
    DefaultLayout,
    SidebarLayout
  },
  data: function() {
      return {
        account: {} as IUser
      }
  },
  methods: {
    setAccount: function(account: IUser) {
        this.account = account;
    }
  },
  computed: {
    layout: function () {
      return this.$route.meta.layout;
    },
  },
});
