
import { defineComponent } from 'vue'
import { IRole } from '../../models/Role'

export default defineComponent({
  name: 'RoleInput',
  props: {
    currentActiveRoles: Array as () => IRole[],
    allRoles: Array as () => IRole[]
  },
  methods: {
    toggleDropdown: function () {
      this.initRoleList();
      this.isOpen = !this.isOpen
    },
    addRole: function (roleId: number) {
      this.inactiveRoles.forEach((element, index) => {
        if (roleId === element.id) {
          this.inactiveRoles.splice(index, 1)
          this.activeRoles.push(element)
          this.isOpen = false
          this.$emit('roles-changed', this.activeRoles.slice())
        }
      })
    },
    removeRole: function (roleId: number) {
      this.activeRoles.forEach((element, index) => {
        if (roleId === element.id) {
          this.activeRoles.splice(index, 1)
          this.inactiveRoles.push(element)
          this.isOpen = false
          this.$emit('roles-changed', this.activeRoles.slice())
        }
      })
    },
    initRoleList: function () {
      this.inactiveRoles.splice(0)
      this.allRoles?.forEach((role) => {
        let contains = false
        this.activeRoles = this.currentActiveRoles!.slice(0)
        this.activeRoles?.forEach(element => {
          if (element.id === role.id) {
            contains = true
          }
        })

        if (!contains) {
          this.inactiveRoles.push(role)
        }
      })
    }
  },
  data: function () {
    return {
      isOpen: false,
      activeRoles: [] as IRole[],
      inactiveRoles: [] as IRole[]
    }
  },
  watch: {
    currentActiveRoles: function() {
      this.initRoleList();
    }
  }
})
